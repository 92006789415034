import React, { useState, useEffect } from "react";

import { Stack, IconButton } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { useAuth } from "hook/useAuth";
import SupportArticleRatingService from "services/rating/supportarticlerating.service";
import {
  PostSupportArticleRatingDto,
  RatingSupportArticleDto,
  SupportArticleRatingDto,
} from "dto/rating/supportarticlerating.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";

type Props = {
  id: string;
};
const service = new SupportArticleRatingService();
const LikeDislikeArticle: React.FC<Props> = ({ id, ...props }) => {
  const { user } = useAuth();
  const [obj, setObj] = useState<RatingSupportArticleDto | null>(null);
  const [loading, setLoading] = useState(false);

  const getRating = () => {
    if (!user) return;
    service.getRating(id, user.id, handleGet, {});
  };
  const handleGet = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    const obj = result.obj ?? null;
    setObj(obj);
  };
  const pressLike = () => {
    if (!user) return;
    setLoading(true);
    const obj = new PostSupportArticleRatingDto(id, user.id);
    service.like(handleAction, {}, obj);
  };

  const pressDislike = () => {
    if (!user) return;
    setLoading(true);
    const obj = new PostSupportArticleRatingDto(id, user.id);
    service.dislike(handleAction, {}, obj);
  };

  const handleAction = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    const ratingObj: SupportArticleRatingDto = result.obj ?? {};
    const rating = ratingObj.rating ?? 0;
    const obj = new RatingSupportArticleDto();
    obj.rating = rating;
    setObj(obj);
    setLoading(false);
  };

  useEffect(() => {
    getRating();
  }, [user]);

  const processButtonDisabledLike = () => {
    if (!obj) return true;
    if (obj.rating === 1) return true;
    return false;
  };

  const processButtonDisabledDislike = () => {
    if (!obj) return true;
    if (obj.rating === -1) return true;
    return false;
  };
  return user && obj ? (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <IconButton
        onClick={pressLike}
        disabled={loading || processButtonDisabledLike()}
      >
        <ThumbUpIcon />
      </IconButton>
      <IconButton
        onClick={pressDislike}
        disabled={loading || processButtonDisabledDislike()}
      >
        <ThumbDownIcon />
      </IconButton>
    </Stack>
  ) : null;
};

export { LikeDislikeArticle };
