import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";

import { Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { BlogCategoryLevel } from "./BlogCategoryLevel";

type BlockBlogCategoryProps = {
    currentRoute: any;
    setBlogCategoryId: any;
    idTypeBlogCategory: string;
    setIdTypeBlogCategory: any;
}
const BlockBlogCategory: React.FC<BlockBlogCategoryProps> = ({
    setBlogCategoryId,
    currentRoute,
    idTypeBlogCategory,
    setIdTypeBlogCategory,
    ...props
}) => {


    return (
        <Box className="blogCategory">
            <BlogCategoryLevel 
            idTypeBlogCategory={idTypeBlogCategory} 
            currentRoute={currentRoute} 
            level={3} 
            idparent={""} 
            setBlogCategoryId={setBlogCategoryId} 
            setIdTypeBlogCategory={setIdTypeBlogCategory}
            />
        </Box>
    );

};

export { BlockBlogCategory };