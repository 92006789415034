import React, { useState, useEffect } from "react";
import { SupportArticleDto } from "dto/supportarticle/supportarticle.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { CommonTools } from "tools/commontools";
import { useResource } from "hook/useResource";

import SupportArticleService from "services/supportarticle/supportarticle.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/status";
import { RouteTools } from "tools/routetools";
import ResultListDTO from "dto/app/resultlist.dto";
import { Box, Grid, List, ListItem, Skeleton, Typography } from "@mui/material";
import { SimilarSupportArticleItem } from "./SimilarSupportArticleItem";

const supportarticleService = new SupportArticleService();

type Props = {
  currentRoute: any;
  idCategory: string;
  idCurrentSupportArticle: string;
};
const SimilarSupportArticleList: React.FC<Props> = ({
  currentRoute,
  idCategory,
  idCurrentSupportArticle,
  ...props
}) => {
  const { LL, CC } = useResource();

  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<SupportArticleDto>>([]);

  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [responseParams, setResponseParams] = useState<any>({});
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("SupportArticleListOnPageOnStatic", "10"))
  );
  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());

  const getList = () => {
    setIsLoading(true);

    reqList.onpage = onPage;
    reqList.page = page;
    reqList.filters = [];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idsupportarticlecategory";
    f.values = [idCategory];
    reqList.filters.push(f);

    supportarticleService.getList(loadObjects, {}, reqList);

    // setIsLoading(false);
  };

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage =
        result.totalpages !== undefined ? result.totalpages : -1;
      const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };

  const checkLoading = () => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;
    // if (objects.length === 0) load = true;
    if (Object.keys(responseParams).length === 0) return true;

    setIsLoading(load);
  };

  useEffect(() => {
    getList();
  }, [idCategory]);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const processItem = (obj: SupportArticleDto, index: number) => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (obj.id === idCurrentSupportArticle) return <></>;
    return (
      <ListItem key={index} sx={{p:0, m:0}}>
        <SimilarSupportArticleItem obj={obj} />
      </ListItem>
    );
  };

  const processList = () => {
    if (isLoading) return <Skeleton variant="rectangular" />;
    if (!objects.length) return <></>;

    return (
      <List>
        {objects.map((item: SupportArticleDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };

  const processComponent = () => {
    if (!(objects.length - 1)) return <></>;
    return (
      <Box mt={3}>
        <Typography variant="h5">{LL("SimilarSupportArticles")}</Typography>
        {processList()}
      </Box>
    );
  };

  return processComponent();
};

export { SimilarSupportArticleList };
