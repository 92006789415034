import React from "react";
import { Box, ListItem, Stack } from "@mui/material";
import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";

import {
  ISupportArticleTypeCategory,
  SupportArticleTypeCategoryObjects,
} from "dto/supportarticle/supportarticlecategory.dto";
import { SupportArticleCategoryItem } from "./SupportArticleTypeCategoryItem";

const SupportArticleTypeCategoryList: React.FC = ({ ...props }) => {
  const processItem = (obj: ISupportArticleTypeCategory, index: number) => {
    if (!obj) return <></>;

    return (
      <ListItem key={index}>
        <SupportArticleCategoryItem obj={obj} />
      </ListItem>
    );
  };

  const processList = () => {
    return (
      <Box className="general-tab-support" my={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          {SupportArticleTypeCategoryObjects.map(
            (item: ISupportArticleTypeCategory, i: number) =>
              processItem(item, i)
          )}
        </Stack>
      </Box>
    );
  };

  return processList();
};

export { SupportArticleTypeCategoryList };
