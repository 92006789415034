import React, { useState, useEffect } from "react";



import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import {
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
    Stack
} from '@mui/material';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { logger } from "hoc/logger";
import { VideoDto } from "dto/video/video.dto";
import CloseIcon from '@mui/icons-material/Close';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { VideoTools } from "tools/videotools";

type VideoListItemCarouselDisplayProps = {
    obj: VideoDto;
    index: number;
}

const VideoListItemCarouselDisplay: React.FC<VideoListItemCarouselDisplayProps> = ({ obj, index, ...props }) => {

    const { LL } = useResource();

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    }

    const processVideoDisplay = () => {
        if (!open) return <></>;
        if (!obj) return <></>;

        return ComponentCommonTools.getFileBlockVideo(obj, 550, 400, true)
    }
    const processItem = () => {
        if (!obj) return (<></>);
        let url = VideoTools.getVideoImageUrl(obj);
        if (!url) url = 'images/no-photo.png';
        if (!open) return (
            <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={1}
            >
                <Box height={210} width={'100%'} position="relative" mt={1}>
                    <img
                        src={url}
                        height={210}
                        width={'100%'}
                        alt="videoimage"
                        
                        style={{ position: 'absolute', zIndex: 0, borderRadius:'5px' }}
                    />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={1}
                    >
                        <IconButton
                            onClick={handleClickOpen}

                            size='large'
                        >
                            <PlayCircleOutlineIcon
                                fontSize="large"
                                sx={{
                                    color: 'white',
                                    fontSize: '60px',
                                }}
                            />
                        </IconButton>
                    </Box>
                </Box>
                <Typography variant="h6" mt={1} textAlign="left" minWidth={"100%"}>
                    {ComponentCommonTools.processObjectField(obj, 'name')}
                </Typography>
            </Box>
        )
        return (
            <Box
                key={index}
            >
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                >
                    <DialogTitle>
                        {ComponentCommonTools.processObjectField(obj, 'name')}
                    </DialogTitle>
                    <IconButton sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        {processVideoDisplay()}
                    </DialogContent>

                </Dialog>

            </Box>
        );
    }



    return processItem();
};

export { VideoListItemCarouselDisplay };
