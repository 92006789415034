import React,{useEffect} from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { HomeSearchBlock } from "components/homepage/HomeSearchBlock";

import { HomeMainBlock } from "components/homepage/HomeMainBlock";
import PageService from "services/page/page.service";
const service = new PageService();
const HomePage: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {


  const getHomeSEO = () => {
    service.getHomeSEO();
  };

  useEffect(() => {
    getHomeSEO()
  }, []);

  return <>
    <HomeSearchBlock currentRoute={currentRoute} />
    <HomeMainBlock currentRoute={currentRoute} />
  </>;
};

export { HomePage };
