import React, { useContext } from "react";
import { useResource } from "hook/useResource";

import { ListItemButton, ListItemText } from "@mui/material";
import { ISupportArticleTypeCategory } from "dto/supportarticle/supportarticlecategory.dto";
import { SupportArticleContext } from "context/supportarticle.context";

type Props = {
  obj: ISupportArticleTypeCategory;
  
};

const SupportArticleCategoryItem: React.FC<Props> = ({
  obj,
  
  ...props
}) => {
  const { LL } = useResource();
  const {
    state: { typeCategory },
    actions: { setTypeCategory },
  } = useContext(SupportArticleContext);
  const handleSelect = () => {
    setTypeCategory(obj.value);
  };

  const isSelected = () => {
    return typeCategory === obj.value;
  };

  const processItem = () => {
    if (!obj) return <></>;

    return (
      <ListItemButton
        selected={isSelected()}
        onClick={handleSelect}
        className="support-category"
      >
        <ListItemText primary={LL(obj.name)} />
      </ListItemButton>
    );
  };

  return processItem();
};

export { SupportArticleCategoryItem };
