import React, { useCallback, useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import SupportArticleCategoryService from "services/supportarticle/supportarticlecategory.service";
import { SupportArticleCategoryDto } from "dto/supportarticle/supportarticlecategory.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { NoResult } from "components/general/NoResult";
import { Skeleton } from "@mui/material";
import { SupportArticleCategoryItem } from "./SupportArticleCategoryItem";

type Props = {
  currentRoute: any;
  level: number;
  idparent: string;
  hasChildren?: boolean;
};

const service = new SupportArticleCategoryService();
export const SupportArticleCategoryLevel: React.FC<Props> = ({
  currentRoute,
  level,
  idparent,
  hasChildren = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [objects, setObjects] =
    useState<Array<SupportArticleCategoryDto> | null>(null);

  const getList = () => {
    const req = new RequestListDTO();
    req.page = 1;
    req.onpage = 100;
    req.filters = [];
    let filter = new RequestFilterDTO();
    filter.field = "idparent";
    filter.values = [idparent];
    req.filters.push(filter);
    setLoading(true);
    service.getList(handleList, {}, req);
  };

  const handleList = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects ?? [];
    setObjects(objects);
  };

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!objects) loading = true;

    setLoading(loading);
  }, [objects]);

  useEffect(() => {
    getList();
  }, [idparent]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processItem = (item: SupportArticleCategoryDto) => {
    const hasChildren =
      item.hasOwnProperty("haschildren") && item.haschildren !== undefined
        ? item.haschildren
        : false;

    return (
      <SupportArticleCategoryItem
        currentRoute={currentRoute}
        level={level}
        obj={item}
        hasChildren={hasChildren}
      />
    );
  };
  const processList = () => {
    if (loading) return <Skeleton variant="rectangular" height={100} />;
    if (!objects) return <></>;
    if (!objects.length) return <></>;
    return <>{objects.map((item) => processItem(item))}</>;
  };
  return processList();
};
