import React, { useState, useEffect } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box, Typography } from "@mui/material";
import { TypeBlogCategoryBlock } from "components/static/typeblogcategory/TypeBlogCategoryBlock";
import { FAQHomeList } from "components/faq/FAQHomeList";
import { HomeBlogCategoryList } from "components/static/blogcategory/HomeBlogCategoryList";
import { VideoListCarousel } from "components/video/VideoListCarousel";
import { useResource } from "hook/useResource";
import { SupportArticleTypeCategoryList } from "components/supportarticlecategory/SupportArticleTypeCategoryList";
import { HomePageArticleList } from "components/supportarticle/HomePageArticleList";
import { SupportArticleCategoryList } from "components/supportarticlecategory/SupportArticleCategoryList";

const HomeMainBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();

  const processVideoCarouselBlock = () => {
    return (
      <Box>
        <Typography variant="h2" mt={4} mb={3}>
          {LL("Video_tutorial")}
        </Typography>
        <VideoListCarousel parent="supportarticle" />
      </Box>
    );
  };

  return (
    <Box className="container">
      <SupportArticleTypeCategoryList />
      <HomePageArticleList />
      {processVideoCarouselBlock()}
      <SupportArticleCategoryList />
    </Box>
  );
};

export { HomeMainBlock };
