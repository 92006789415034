import React from "react";

import { SupportArticleDto } from "dto/supportarticle/supportarticle.dto";

import { ComponentCommonTools } from "tools/componentcommontools";

import { RouteTools } from "tools/routetools";
import { ListItemButton, ListItemText } from "@mui/material";

type Props = {
  obj: SupportArticleDto;
};

const SimilarSupportArticleItem: React.FC<Props> = ({ obj, ...props }) => {
  const handleOnClick = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("fullurl")) return;
    if (!obj.fullurl) return;
    const url = obj.fullurl;
    RouteTools.setHistory(url, {});
  };
  const processItem = () => {
    if (!obj) return <></>;
    return (
      <ListItemButton onClick={handleOnClick} sx={{p:0, m:0}}>
        <ListItemText className="list-item">
          {ComponentCommonTools.processObjectField(obj, "name")}
        </ListItemText>
      </ListItemButton>
    );
  };

  return processItem();
};

export { SimilarSupportArticleItem };
