import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";

import { MySelect } from "components/elements/Select/MySelect";
import { Types } from "tools/types"
import SelectOptions from "dto/app/selectoptions.dto";
type TypesSelectProps = {
    setObj: ((field: any, value: any) => void);
    field: any;
    _defaultValue?: number;
    typesType?: string;
    _label?: string;
    [key: string]: any; // for the rest of the props which we do not have types for
}

const TypesSelect: React.FC<TypesSelectProps> = ({
    setObj,
    field,
    _defaultValue,
    typesType,
    _label,
    ...props
}) => {
    const { LL } = useResource();

    
    const label = _label ? _label : "Types";
    const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
    const [loading, setLoading] = useState<boolean>(true);
    const [value, setValue] = useState<number | any>();

    useEffect(() => {
        
        if (!typesType) typesType = 'clasificator';
        const options: Array<SelectOptions> = Types.GA(typesType);
        setOptions(options);
        
    }, [typesType]);

    useEffect(() => {
        setValue('')
        if (_defaultValue) {
            setValue(_defaultValue);
        }
      
    }, [_defaultValue]);

    useEffect(() => {
        
        if (value) setObj(field, value);
    }, [value]);



    return options.length > 0 ? (
        <MySelect
            options={options}
            _label={label}
            setValue={setValue}
            value={value}
            {...props}
        />
    ) : (<></>);

};

export { TypesSelect };
