import React, { useCallback, useEffect, useState } from "react";
import SupportArticleService from "services/supportarticle/supportarticle.service";
import { SupportArticleDto } from "dto/supportarticle/supportarticle.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Skeleton, Box } from "@mui/material";
import { SupportArticleItem } from "./SupportArticleItem";
import { useResource } from "hook/useResource";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";

type Props = {
  currentRoute: any;
  idCategory: string;
};

const service = new SupportArticleService();
export const SupportArticleList: React.FC<Props> = ({
  currentRoute,
  idCategory,
}) => {
  const { CC } = useResource();
  const [loading, setLoading] = useState(true);
  const [objects, setObjects] = useState<Array<SupportArticleDto> | null>(null);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("DefaultOnPageSupportArticleList", 10))
  );
  const getList = () => {
    if(!idCategory) return;
    const req = new RequestListDTO();
    req.page = 1;
    req.onpage = onPage;
    req.filters = [];
    let filter = new RequestFilterDTO();
    filter.field = "idsupportarticlecategory";
    filter.values = [idCategory];
    req.filters.push(filter);
    setLoading(true);
    service.getList(handleList, {}, req);
  };

  const handleList = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects ?? [];
    const total = result.total !== undefined ? result.total : -1;
    const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
    setObjects(objects);
    setTotal(total);
    setTotalPage(totalPage);
  };

  const processLoadMoreObjects = () => {
    if (!objects || !objects.length) return <></>;
    return (
      <LoadMoreButton
        objects={objects}
        total={total}
        onPage={onPage}
        setOnPage={setOnPage}
        step={parseInt(CC("DefaultOnPageSupportArticleList", 4))}
        className="loadMorebtn"
      />
    );
  };

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!objects) loading = true;
    if (total === -1) return true;
    if (totalPage === -1) return true;

    setLoading(loading);
  }, [objects, total, totalPage]);

  useEffect(() => {
    getList();
  }, [idCategory, onPage]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processItem = (item: SupportArticleDto) => {
    if (!item) return <></>;
    return <SupportArticleItem currentRoute={currentRoute} obj={item} />;
  };
  
  const processList = () => {
    if (loading) return <Skeleton variant="rectangular" />;
    if (!objects) return <></>;
    if (!objects.length) return <></>;
    return (
      <Box >
        <Box>{objects.map((item) => processItem(item))}</Box>
        <Box>{processLoadMoreObjects()}</Box>
      </Box>
    );
  };
  return processList();
};
