import { VideoDto } from "dto/video/video.dto";
import React, { useState, useEffect } from "react";
import { Types } from "tools/types";
import { VideoListItemPlayerDisplay } from "./VideoListItemPlayerDisplay";
import { VideoListItemTextualDisplay } from "./VideoListItemTextualDisplay";
import { VideoListItemCarouselDisplay } from "./VideoListItemCarouselDisplay";



type VideoListItemProps = {
    obj: VideoDto;
    typeDisplay: number;
    index: number;
}

const VideoListItem: React.FC<VideoListItemProps> = ({ 
    obj, 
    typeDisplay,
    index,
    ...props }) => {


    const processItem = () => {
        if (!obj) return <></>
       
        if(typeDisplay === Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_PLAYER) {
            return <VideoListItemPlayerDisplay obj={obj} index={index} />
        }
        if(typeDisplay === Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_TEXTUAL) {
            return <VideoListItemTextualDisplay obj={obj} index={index} />
        }
        if(typeDisplay === Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_CAROUSEL) {
            return <VideoListItemCarouselDisplay obj={obj} index={index} />
        }
        
        return <></>;

    }



    return processItem();
};

export { VideoListItem };
