import React, { useContext, useEffect } from "react";
import { useResource } from "hook/useResource";

import Skeleton from "@mui/material/Skeleton";
import { Grid, List, ListItem, Typography } from "@mui/material";

import { SupportArticleContext } from "context/supportarticle.context";
import { SupportArticleDto } from "dto/supportarticle/supportarticle.dto";
import { HomePageArticleItem } from "./HomePageArticleItem";

const HomePageArticleList: React.FC = () => {
  const { LL } = useResource();
  const {
    state: { articleHomePageObjects, isLoadingHomePageArticle, typeCategory },
    actions: { getHomePageArticles },
  } = useContext(SupportArticleContext);

  useEffect(() => {
    getHomePageArticles();
  }, [typeCategory]);

  const processItem = (obj: SupportArticleDto, index: number) => {
    if (!obj) return <></>;

    return (
      <Grid item sm={6} md={4} key={index}>
        <ListItem key={index} sx={{ p: 0, m: 0 }}>
          <HomePageArticleItem obj={obj} />
        </ListItem>
      </Grid>
    );
  };

  const processList = () => {
    if (!articleHomePageObjects.length) return <></>;

    return (
      <>
        <Typography variant="h2" my={2}>
          {LL("FAQHomeList_TITLE")}
        </Typography>

        <List>
          <Grid container spacing={2}>
            {articleHomePageObjects.map(
              (item: SupportArticleDto, i: number) => {
                return processItem(item, i);
              }
            )}
          </Grid>
        </List>
      </>
    );
  };

  return isLoadingHomePageArticle ? <Skeleton /> : processList();
};

export { HomePageArticleList };
