import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import AppBar from '@mui/material/AppBar';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import SignInDto from "dto/auth/signin.dto";

import Button from "@mui/material/Button";
import { Loading } from "components/general/Loading";
import MyTextField from "components/form/mytextfield";
import MyCheckboxField from "components/form/mycheckboxfield";
import RequiredValidator from "validators/required.validator";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";

import InputAdornment from '@mui/material/InputAdornment';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { logger } from "hoc/logger";
import Typography from "@mui/material/Typography";
import SignUpGoogleResultDto from "dto/auth/signup.dto";
import { MyNavBt } from "components/general/MyNavBt";
import { AuthMethodsBlock } from "components/auth/AuthMethodsBlock";
import Container from '@mui/material/Container';
import { PasswordStrengthChecker } from "components/auth/PasswordStrengthChecker";
import { RegisterUserDto } from "dto/auth/registeruser.dto";
import MyDatePicker from 'components/elements/MyDatePicker/MyDatePicker';
import { PhoneTypeSelect } from 'components/elements/Select/PhoneTypeSelect';
import { CountryCodeSelect } from 'components/elements/Select/CountryCodeSelect';
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { CommonTools } from "tools/commontools";

const SignUpGoogleResult: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const { signUp } = useAuth();

    const { LL } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [isFormDisabled, setIsFormDisabled] = useState(true);
    
    const checkIsLoading = () => {
        if (!isLoading) return;
        
        setIsLoading(false);
    };

    useEffect(() => {
        checkIsLoading();
    }, []);

    return isLoading ? (
      <Loading />
    ) : (
      <Box>
                {JSON.stringify(currentRoute)}
                {"<hr><hr><hr>"}
        {window.location.hash}
      </Box>
    );
};

export { SignUpGoogleResult };
