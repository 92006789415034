import React, { useState, useEffect, useContext } from "react";
import { useResource } from "hook/useResource";
import { CourseFilterDto } from "dto/course/coursefilter.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import CourseFilterService from "services/course/coursefilter.service";
import ResultListDTO from "dto/app/resultlist.dto";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
  Divider,
  Stack,
  Grid
} from "@mui/material";
import { logger } from "hoc/logger";

import { ComponentCommonTools } from "tools/componentcommontools";
import { SupportArticleDto } from "dto/supportarticle/supportarticle.dto";
import SupportArticleService from "services/supportarticle/supportarticle.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { HtmlTag } from "components/general/HtmlTag";
import { MainObjectImage } from "components/elements/media/MainObjectImage";
import { ObjectGallery } from "components/elements/media/ObjectGallery";
import { ObjectAttachment } from "components/elements/media/ObjectAttachemnt";
import { MyBreadcrumbsCourse } from "components/elements/MyBreadcrumbs/MyBreadcrumbsCourse";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";
import { SupportArticleContext } from "context/supportarticle.context";
import { AttachmentListLesson } from "components/attachment/AttachmentListLesson";
import { VideoList } from "components/video/VideoList";
import { Types } from "tools/types";
import { SimilarSupportArticleList } from "./SimilarSupportArticleList";
import { ContactBlock } from "components/support/ContactBlock";
import { LikeDislikeArticle } from "./LikeDislikeArticle";
import { SupportArticleCategoryMenuList } from "components/supportarticlecategory/SupportArticleCategoryMenuList";
import DateTools from "tools/date.tools";

type SupportArticleBlockProps = {
  currentRoute: any;
};

const supportarticleService = new SupportArticleService();

const SupportArticleBlock: React.FC<SupportArticleBlockProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const {
    actions: { getMainCategoryByCode },
  } = useContext(SupportArticleContext);
  const [isLoading, setIsLoading] = useState(true);

  const [idObject, setIdObject] = useState<string>("");
  const [obj, setObj] = useState<SupportArticleDto>();
  const processCategoryObject = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("supportarticlecategory")) return null;
    if (!obj.supportarticlecategory) return null;
    if (!obj.supportarticlecategory.hasOwnProperty("maincode")) return null;
    if (!obj.supportarticlecategory.maincode) return null;
    return getMainCategoryByCode(obj.supportarticlecategory.maincode);
  };
  const getIdObj = () => {
    let id =
      currentRoute &&
      currentRoute.processedData &&
      currentRoute.processedData.idobj
        ? currentRoute.processedData.idobj
        : "";

    if (!id) {
      id =
        currentRoute &&
        currentRoute.processedData &&
        currentRoute.processedData._obj &&
        currentRoute.processedData._obj.id
          ? currentRoute.processedData._obj.id
          : id;
    }

    if (!id) {
      id =
        currentRoute && currentRoute._getVars && currentRoute._getVars.id
          ? currentRoute._getVars.id
          : id;
    }

    let _obj =
      currentRoute &&
      currentRoute.processedData &&
      currentRoute.processedData._obj
        ? currentRoute.processedData._obj
        : undefined;

    logger("getObj", id, _obj);
    setIdObject(id);
    setObj(_obj);
  };

  useEffect(() => {
    getIdObj();
  }, [currentRoute]);

  const getObj = () => {
    if (idObject == undefined || idObject == "" || idObject == null) return;

    if (obj != undefined && obj.id != undefined && obj.id == idObject) {
      return;
    }
    logger("getObj", idObject);
    supportarticleService.get(idObject, loadObjects);
  };

  useEffect(() => {
    getObj();
  }, [idObject]);

  const loadObjects = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      const _obj: SupportArticleDto | undefined =
        result.obj != undefined ? (result.obj as SupportArticleDto) : undefined;
      setObj(result.obj);
    }
  };

  const processBreadcrumbChildren = (): BreadcrumbChildrenDto[] => {
    const breadcrumbArray: BreadcrumbChildrenDto[] = [];
    let breadcrumb = new BreadcrumbChildrenDto();
    breadcrumb.url = "/";
    breadcrumb.label = LL("crumb_homepage");
    breadcrumbArray.push(breadcrumb);
    const categoryObject = processCategoryObject();

    if (categoryObject) {
      breadcrumb = new BreadcrumbChildrenDto();
      const _id = categoryObject.id ? categoryObject.id : "";
      breadcrumb.url = "/supportarticlecategory/" + _id;
      breadcrumb.label = categoryObject.name ? categoryObject.name : "";
      breadcrumbArray.push(breadcrumb);
    }

    if (obj && obj.hasOwnProperty("name") && obj.name) {
      const breadcrumbChildrenItem = new BreadcrumbChildrenDto();
      breadcrumbChildrenItem.url = "";
      breadcrumbChildrenItem.label = obj.name;
      breadcrumbArray.push(breadcrumbChildrenItem);
    }

    return breadcrumbArray;
  };

  const processBreadcrumb = () => {
    if (!obj) return <></>;
    const breadcrumbChildren = processBreadcrumbChildren();
    return (
      <Box className="breadcrumbs">
        <MyBreadcrumbsCourse
          currentRoute={currentRoute}
          childs={breadcrumbChildren}
        />
      </Box>
    );
  };

  const checkLoading = () => {
    let load = false;

    if (!obj || obj == undefined || obj == null) load = true;

    setIsLoading(load);
  };

  useEffect(() => {
    checkLoading();
  }, [idObject, obj]);

  const processMainImage = () => {
    if (!obj) return <></>;

    const _gallery = obj.gallery ? obj.gallery : undefined;
    return (
      <Box className="image">
        <MainObjectImage gallery={_gallery} />
      </Box>
    );
  };

  const processHead = () => {
    if (!obj) return <></>;

    return (
      <Box>
        <Typography variant="h2">
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Typography>
        <Typography>
          {ComponentCommonTools.processObjectField(obj, "shortdescription")}
        </Typography>
      </Box>
    );
  };

  const processContent = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("content")) return <></>;
    if (!obj.content) return <></>;
    return <HtmlTag content={obj.content} />;
  };

  const processGallery = () => {
    if (!obj) return <></>;

    const _gallery = obj.gallery ? obj.gallery : undefined;
    return <ObjectGallery serverGallery={_gallery} nonMain={true} />;
  };

  const processAttachments = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;

    return (
      <AttachmentListLesson
        idparent={obj.id}
        parent="supportarticle"
        disabled={false}
      />
    );
  };

  const processVideos = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    return (
      <VideoList
        idparent={obj.id}
        parent={"supportarticle"}
        typeDisplay={Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_PLAYER}
      />
    );
  };

  const processSimilarSupportArticle = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (!obj.hasOwnProperty("idsupportarticlecategory")) return <></>;
    if (!obj.idsupportarticlecategory) return <></>;
    return (
      <SimilarSupportArticleList
        idCurrentSupportArticle={obj.id}
        idCategory={obj.idsupportarticlecategory}
        currentRoute={currentRoute}
      />
    );
  };

  const processLikeDislikeArticle = () => {
    if (!obj) return <></>;
    logger("processLikeDislikeArticle", obj);
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    return <LikeDislikeArticle id={obj.id} />;
  };

  const processCategoryMainCode = () => {
    if (!obj) return "";
    if (!obj.hasOwnProperty("supportarticlecategory")) return "";
    if (!obj.supportarticlecategory) return "";
    if (!obj.supportarticlecategory.hasOwnProperty("maincode")) return "";
    if (!obj.supportarticlecategory.maincode) return "";
    return obj.supportarticlecategory.maincode;
  };

  const processDate = () => {
    if (!obj) return "";
    if (!obj.hasOwnProperty("date")) return "";
    if (!obj.date) return "";
    return DateTools.convertToDate(obj.date).toLocaleDateString("en-GB");
  };
  return isLoading ? (
    <Skeleton />
  ) : (
    <Box className="pageGeneral supportarticleDetail">
      <Box className="onlyBread" py={3}>
        <Box className="container">{processBreadcrumb()}</Box>
      </Box>

      <Box className="container" py={4}>
          {processHead()}
      </Box>

      <Divider />
<Box className="bg-right">
      <Grid container className="container">
        <Grid item sm={9} className="content-support-alt">
          {processContent()}
          {processAttachments()}
          {processVideos()}
          <Stack direction={"row"} sx={{alignContent:'center', alignItems:'center', justifyContent:'space-between', my:3}} >
            {processLikeDislikeArticle()}
            <Typography variant="caption">{processDate()}</Typography>
          </Stack>
          
          <Divider></Divider>
          <Stack direction={"row"} sx={{alignContent:'center', justifyContent:'space-between', my:5}} >
            <Typography variant="h6">{LL("contact_us_if_you_did_not_find_the_solution")}</Typography>
            <ContactBlock currentRoute={currentRoute} />
          </Stack>
          
          
        </Grid>
        <Grid item sm={3} px={2}>
          {processSimilarSupportArticle()}
          <SupportArticleCategoryMenuList mainCode={processCategoryMainCode()} />
        </Grid>
      </Grid>


      
   
      {/* <Box>
        <SupportArticleCategoryMenuForStaticSupportArticle
          currentRoute={currentRoute}
        />
      </Box> */}
      {/*<Box><Box className="container supportarticleSize">{processGallery()}</Box>
      {processAttachments()}</Box>
      <Box>{processVideos()}</Box> */}
    </Box>
    </Box>
  );
};

export { SupportArticleBlock };
