import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { FAQDto } from 'dto/faq/faq.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { Box, Stack, Divider, ListItemButton, ListItemText, Link } from "@mui/material";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import { HtmlTag } from "components/general/HtmlTag";

type FAQItemMenuProps = {
    obj: FAQDto;
    idCurrent: string;
    setIdCurrent: any;
}

const FAQItemMenu: React.FC<FAQItemMenuProps> = ({
    obj,
    idCurrent,
    setIdCurrent,

    ...props }) => {

    const { LL } = useResource();

    const isSelected = (id: string) => {
        return idCurrent === id;
    }

    const handleSelect = (id?: string) => {
        if (!id) return;
        setIdCurrent(id);
    }

    useEffect(() => {
        if (!idCurrent) return;
        scrollToAnchor(idCurrent);
    }, [idCurrent]);

    const processItem = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>

        return (
            <ListItemButton
                onClick={() => handleSelect(obj.id)}
                selected={isSelected(obj.id)}
            >
                <ListItemText>
                    {ComponentCommonTools.processObjectField(obj, 'question')}
                </ListItemText>
            </ListItemButton>
        );

    };
    const scrollToAnchor = (id?: string) => {
        if (!id) return;
        const anchor = document.getElementById(id);
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };



    return processItem();
};

export { FAQItemMenu };
