

import React, { useEffect, useState, useRef } from "react";

import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";


import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Stack,
    Typography,
    ListItemIcon,
    ListItemButton,
    Link
} from "@mui/material";


import { AttachmentDto } from "dto/attachment/attachment.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ComponentCommonTools } from "tools/componentcommontools";
import AttachmentService from "services/attachment/attachment.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Status } from "tools/status";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { FileTools } from "tools/filetools";
const attachmentService = new AttachmentService();
type AttachmentListLessonProps = {
    idparent: string;
    parent: string
    disabled: boolean;
}
const AttachmentListLesson: React.FC<AttachmentListLessonProps> = ({
    idparent,
    parent,
    disabled,

    ...props
}) => {
    const { LL, CC } = useResource();
    const [loading, setLoading] = useState<boolean>(false);
    const [objects, setObjects] = useState<Array<AttachmentDto>>([]);

    const getList = () => {
        if (!idparent) return;
        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC('DefaultOnPageAttachmentListLesson', 1000));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "ordercriteria";
        sortCriteria.asc = true;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);


        f = new RequestFilterDTO();
        f.field = "idparent";
        f.values = [idparent];
        reqList.filters.push(f);
        setLoading(true);

        attachmentService.getList(loadObjects, {}, reqList);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }

    const checkLoading = () => {
        let load = false;
        if (!objects) load = true;
        setLoading(load);
    }

    useEffect(() => {
        checkLoading();
    }, [objects]);

    useEffect(() => {
        getList();
    }, [idparent]);

    const processItemUrl = (item: AttachmentDto) => {
        
        if (!item) return '';
        if (!item.hasOwnProperty('idfile')) return '';
        if (!item.idfile) return '';
        const url = FileTools.getFileUrl(item.idfile);
        return url;
    }
    const processItem = (item: AttachmentDto, i: number) => {
        if (!item) return (<></>);
        if (!item.hasOwnProperty('id')) return (<></>);
        if (!item.id) return (<></>);
        let name = ''
        if (item.hasOwnProperty('name') && item.name) name = item.name;
        else {
            const index = i + 1;
            name = LL('attachment') + ' ' + index.toString();
        }
        const url = processItemUrl(item);
        return (
            <ListItem
                key={i}
                disablePadding
            >
                <ListItemButton
                    disabled={disabled}
                    component={Link}
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                    
                >
                    <ListItemIcon>
                        <FilePresentIcon />
                    </ListItemIcon>

                    <ListItemText primary={name} />
                </ListItemButton >
            </ListItem>
        );
    }
    const processLessonList = () => {
        if (loading) return <Skeleton variant="rectangular" height={50} />;
        if (!objects.length) return <></>;

        return (
            <List>
                {objects.map((item: AttachmentDto, i: number) => {
                    return processItem(item, i);
                })}
            </List >
        );
    };
    return (processLessonList())
};

export { AttachmentListLesson };