import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";


import { Box, Typography } from "@mui/material";



import { RouteTools } from "tools/routetools";
import { BlockBlogCategory } from "./BlockBlogCategory";


const BlogCategoryMenuForStaticBlog: React.FC<PageComponentProps> = ({
    currentRoute,
    
    
    ...props
}) => {


    const { LL, setSearchFilterBlog } = useResource();
    const studentIdTypeBlogCategory = process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY ?
    process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY : "";
const teacherIdTypeBlogCategory = process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY_TEACHER ?
    process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY_TEACHER : "";
    
    const [idBlogCategory, setIdBlogCategory] = useState<string>("");
    const [idTypeBlogCategory, setIdTypeBlogCategory] = useState<string>("");
   
    const handleNavigateCategory = () => {
        if(!idBlogCategory) return;
        
        const state: any = {}
        state.idBlogCategory = idBlogCategory;
        RouteTools.setHistory('/blog', state)
    }

    useEffect(() => {
        handleNavigateCategory();
    }, [idBlogCategory])

    const handleNavigateTypeCategory = (id:string) => {
        
        const state: any = {}
        state.idTypeBlogCategory = id;
        RouteTools.setHistory('/blog', state)
    }

    

    const handleSetAllCategories = () => {
        handleNavigateTypeCategory("")
    }

    const handleSetStudentCategories = () => {
        handleNavigateTypeCategory(studentIdTypeBlogCategory);
    }

    const handleSetTeacherCategories = () => {
        handleNavigateTypeCategory(teacherIdTypeBlogCategory);
    }

    const processBlogCategoryList = () => {
        return (<Box >
            <Typography
                variant="h3"
                my={3}
                onClick={handleSetAllCategories}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#0072EF',
                    },
                }}
            >
                {LL("All_Categories")}
            </Typography>
            <Typography
                variant="h4"
                my={3}
                onClick={handleSetStudentCategories}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#0072EF',
                    },
                }}
            >
                {LL("Student_Categories")}
            </Typography>
            <BlockBlogCategory
                idTypeBlogCategory={studentIdTypeBlogCategory}
                currentRoute={currentRoute}
                setBlogCategoryId={setIdBlogCategory}
                setIdTypeBlogCategory={setIdTypeBlogCategory}
            />

            <Typography
                variant="h4"
                my={3}
                onClick={handleSetTeacherCategories}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#0072EF',
                    },
                }}
            >
                {LL("Teacher_Categories")}
            </Typography>
            <BlockBlogCategory
                idTypeBlogCategory={teacherIdTypeBlogCategory}
                currentRoute={currentRoute}
                setBlogCategoryId={setIdBlogCategory}
                setIdTypeBlogCategory={setIdTypeBlogCategory}
            />
        </Box>)
    }
    return processBlogCategoryList()

};

export { BlogCategoryMenuForStaticBlog };