import React from "react";
import { useResource } from "hook/useResource";

import { Box, Card, CardActionArea, CardContent } from "@mui/material";
import { SupportArticleCategoryDto } from "dto/supportarticle/supportarticlecategory.dto";
import { ComponentCommonTools } from "tools/componentcommontools";
import { RouteTools } from "tools/routetools";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack
} from '@mui/material';
type Props = {
  obj: SupportArticleCategoryDto;
};

const SupportArticleCategoryItem: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();

  const handleSelect = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;
    const url = "/supportarticlecategory/" + obj.id;
    RouteTools.setHistory(url, {});
  };

  const processImage = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("gallery")) return <></>;
    if (!obj.gallery) return <></>;
    if (!obj.gallery.length) return <></>;
    return ComponentCommonTools.getFileBlockImage(obj.gallery[0]?.fileInfoObj, 63, 63);
  };
  const processItem = () => {
    if (!obj) return <></>;

    return (

          <Box  onClick={handleSelect}>
            <Box>{processImage()}</Box>
            <Typography variant="h4" sx={{my:2}}>{ComponentCommonTools.processObjectField(obj, "name")}</Typography>
            <Typography >
              {ComponentCommonTools.processObjectField(obj, "shortdescription")}
            </Typography>
          </Box>

    );
  };

  return processItem();
};

export { SupportArticleCategoryItem };
