import React from "react";

import { ListItemButton, ListItemText } from "@mui/material";

import { RouteTools } from "tools/routetools";
import { SupportArticleDto } from "dto/supportarticle/supportarticle.dto";
import { ComponentCommonTools } from "tools/componentcommontools";

type Props = {
  obj: SupportArticleDto;
};

const HomePageArticleItem: React.FC<Props> = ({
  obj,

  ...props
}) => {
  const handleSelect = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("fullurl")) return;
    if (!obj.fullurl) return;

    RouteTools.setHistory(obj.fullurl, {});
  };

  const processItem = () => {
    if (!obj) return <></>;

    return (
      <ListItemButton onClick={handleSelect} className="item-faq-block">
        <ListItemText
          primary={ComponentCommonTools.processObjectField(obj, "name")}
        />
      </ListItemButton>
    );
  };

  return processItem();
};

export { HomePageArticleItem };
