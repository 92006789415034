import React, { useState, useEffect } from "react";

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { TicketMessageDto } from 'dto/support/ticketmessage.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { ListItemText, Link, ListItemIcon, ListItem } from "@mui/material";
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { logger } from "hoc/logger";
import { VideoDto } from "dto/video/video.dto";


type VideoListItemTextualDisplayProps = {
    obj: VideoDto;
    index: number;
}

const VideoListItemTextualDisplay: React.FC<VideoListItemTextualDisplayProps> = ({ obj,index, ...props }) => {

    const { LL } = useResource();


    const processItem = () => {
        if (!obj) return (<></>);
        if (!obj.hasOwnProperty('id')) return (<></>);
        if (!obj.id) return (<></>);
        let name = ''
        if (obj.hasOwnProperty('name') && obj.name) name = obj.name;
        else {
            const indexLocal = index + 1;
            name = LL('video') + ' ' + indexLocal.toString();
        }
        return (
            <ListItem
                key={index}

            >
                <ListItemIcon>
                    <VideoFileIcon />
                </ListItemIcon>
                <ListItemText primary={name} />

            </ListItem>
        );
    }



    return processItem();
};

export { VideoListItemTextualDisplay };
