import React, { useContext, useEffect } from "react";
import { ComponentCommonTools } from "tools/componentcommontools";
import {
  Box,
  Skeleton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";

import { SupportArticleContext } from "context/supportarticle.context";
import { useResource } from "hook/useResource";
import { SupportArticleCategoryDto } from "dto/supportarticle/supportarticlecategory.dto";
import { CategorySearchCodeFilterItem } from "./CategorySearchCodeFilterItem";
import { RouteTools } from "tools/routetools";
import { useCookies } from "react-cookie";

type Props = {
  currentRoute: any;
};

const CategorySearchCodeFilterList: React.FC<Props> = ({ currentRoute }) => {
  const {
    actions: {
      setCodeFilterCriteria,
      getArticlesCategoryListSearch,
      processTotalNumberOfArticles,
    },
    state: {
      articleCategorySearchObjects,
      loadingCategorySearch,
      codeFilterCriteria,
      searchArticle,
    },
  } = useContext(SupportArticleContext);
  const { LL } = useResource();

  const processItem = (obj: SupportArticleCategoryDto) => {
    if (!obj) return <></>;
    return <CategorySearchCodeFilterItem  item={obj} />;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodeFilterCriteria((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    getArticlesCategoryListSearch();
  }, [searchArticle]);

  const processList = () => {
    const objects = SupportArticleCategoryDto.groupByType(
      articleCategorySearchObjects
    );
    if (!objects) return <></>;
    return objects.map((obj) => {
      return (
        <Box my={2}>
          <Box>
            <Typography variant="h6">{obj.name}</Typography>
          </Box>
          <Box>
            {obj.objects.map((item) => {
              return processItem(item);
            })}
          </Box>
        </Box>
      );
    });
  };
  const processComponent = () => {
    if (!processTotalNumberOfArticles()) return <></>;
    return (
      <Box sx={{width:"100%", my:2}}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={codeFilterCriteria}
            name="radio-buttons-group"
            value={codeFilterCriteria}
            onChange={handleChange}
          >
            <FormControlLabel
              value={""}
              control={<Radio />}
              label={LL("All") + " (" + processTotalNumberOfArticles() + ")"}
            />
            {processList()}
          </RadioGroup>
        </FormControl>
      </Box>
    );
  };

  return !loadingCategorySearch ? (
    processComponent()
  ) : (
    <Skeleton variant="rectangular" />
  );
};

export { CategorySearchCodeFilterList };
