import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";

import { ThemeProvider } from "@mui/system";
import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import { logger } from "hoc/logger";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { GoogleIcon } from "components/general/GoogleIcon";
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';


import { GoogleLogin } from "react-google-login";

type MyGoogleLoginButtonProps = {}

const MyGoogleLoginButton: React.FC<MyGoogleLoginButtonProps> = ({ ...props }) => {


    const { LL } = useResource();
    
    const responseGoogle = (response:any) => {
      console.log("responseGoogle", response);
      // Handle the response (e.g., send it to the server)
    };
    
    const errorResponseGoogle = (response:any) => {
      console.log("errorResponseGoogle",response);
      // Handle the response (e.g., send it to the server)
  };
  
  
          // --- ClientSecret: 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 ---

  return (
      
        <GoogleLogin
            clientId="1075539502430-nup9hpj3d1a9snmohs1nji6csp5duf6e.apps.googleusercontent.com"
            buttonText="Login with Google"
            // onSuccess={responseGoogle}
            // onFailure={errorResponseGoogle}
            cookiePolicy={"single_host_origin"}
            uxMode="redirect"
            // redirectUri={"https://piesa.md/paynet/notifypayment"}
            redirectUri={"http://testliveclassro.com/signup-google-result"}
          >
            <MyButton
              type="button"
              children={LL("Bt_Google")}
              cb={() => {
                logger("Google");
              }}
              variant="text"
              color="primary"
              className="btn-auth-social"
              startIcon={<GoogleIcon />}
            />
    </GoogleLogin>
    
    );
};

export { MyGoogleLoginButton };
