import React, { useEffect, useState, useRef } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import {
    IconButton,
    Stack,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Box

} from "@mui/material";
import MyTextField from 'components/form/mytextfield';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from "hook/useAuth";
import { TypesSelect } from "components/elements/Select/TypesSelect";
import { RegisterTicketDto } from "dto/support/ticket.dto";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { CircularLoading } from "components/general/CircularLoading";
import { MyButton } from "components/general/MyButton";
import { TicketCategorySelect } from "components/elements/Select/TicketCategorySelect";
import { Types } from "tools/types";
import TicketService from "services/support/ticket.service";
import ResultObjectDTO from "dto/app/resultobject.dto";

type ContactFormDialogProps = {
    open: boolean;
    setOpen: any;
}
const ticketService = new TicketService();
const ContactFormDialog: React.FC<ContactFormDialogProps> = ({
    open,
    setOpen,
    ...props
}) => {

    const { LL } = useResource();
    const { student, teacher } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<RegisterTicketDto>(new RegisterTicketDto());
    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(true);

    const validateAll = () => {
        
        if (!obj) return;
        

        obj.message = obj.message !== undefined ? obj.message : '';
        obj.type = obj.type ?? Types.TYPE_TICKET_STUDENT;

        setObj(obj);
    };

    const handleClose = () => {
        setOpen(false);
        setObj(new RegisterTicketDto());
    };


    const processTypeTicketSelect = () => {
        if (!obj) return <></>;
        if (!teacher) {
            return (<></>)
        }
        return (
            <Box>
                <Typography variant="h6" mb={1}> {LL('Type_Ticket')}</Typography>
                <TypesSelect
                    id="type"
                    name="type"
                    variant="standard"
                    className="form-select"
                    color="primary"
                    setObj={setObjField}
                    field="type"
                    value={obj.type}
                    typesType="tickettype"
                />
            </Box>
        )
    }

    const processValidators = () => {
        setLoading(true);
        let v: any = {
            message: [],
        };

        v.message.push(new RequiredValidator('', ''));
        setValidators(v);
    };

    useEffect(() => {
        processValidators();
        validateAll();
    }, [open]);

    const checkLoading = () => {
        
        if (!validators) return;

        setLoading(false);
    };
    useEffect(() => {
        checkLoading();
    }, [validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [
        vresults,
        obj.type,
        obj.idticketcategory
    ]);



    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };
    const checkIsFormDisabled = () => {
        let rez = ValidateTools.vForm(vresults, 'message');

        if (!obj.type) rez = true;
        if (!obj.idticketcategory) rez = true;
        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        if (!obj) return;

        if (obj.type === Types.TYPE_TICKET_STUDENT) {
            obj.idstudent = student.id;
        } else if (obj.type === Types.TYPE_TICKET_TEACHER) {
            obj.idteacher = teacher.id;
        }
        
        setLoading(true);
        ticketService.register(handleResult, {}, obj);
    };

    const handleResult = (result: ResultObjectDTO, cbparams: any) => {
        if (!result) return;
        if (!result.err) {
            
            setOpen(false);
            setLoading(false);
            setObj(new RegisterTicketDto());
        }
    }

    const processForm = () => {

        if (!obj) return <></>;
        if (loading) return <CircularLoading />;

        return (
            <form onSubmit={handleSubmit}>
                {processTypeTicketSelect()}
                <Box>
                    <Typography variant="h6" mb={1}> {LL('TicketCategory')}</Typography>
                    <TicketCategorySelect
                        tabIndex={1}
                        id="idticketcategory"
                        name="idticketcategory"
                        variant="standard"
                        color="primary"
                        className="form-select"
                        value={obj.idticketcategory}
                        field="idticketcategory"
                        size="small"
                        setObj={setObjField}
                        obj={obj}
                    />

                </Box>
                <Box>
                    <Typography variant="h6" mb={1}> {LL('message')}</Typography>
                    <MyTextField
                        tabIndex={2}
                        fullWidth
                        id="message"
                        name="message"
                        minRows={4}
                        variant="standard"
                        color="primary"
                        className="form-input"
                        _label={LL("message")}
                        size="small"
                        value={obj.message}
                        setObj={setObjField}
                        multiline
                        _vresults={vresults && vresults.message ? vresults.message : false}
                    />
                </Box>
                

                <Box textAlign='center' my={4}>
                    <MyButton
                        tabIndex={1}
                        disabled={isFormDisabled}
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                        className="btn"
                        children={LL("Bt_Submit")}
                    />
                </Box>
            </form>
        )
    }

    const processComponent = () => {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle>{LL("Contact_Us")}</DialogTitle>
                <IconButton sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {processForm()}
                </DialogContent>
            </Dialog>
        )
    }

    return processComponent();

}

export { ContactFormDialog };