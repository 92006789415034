import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import AdvertisementService from "services/advertisement/advertisement.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementDto } from "dto/advertisement/advertisement.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MyNavBt } from "components/general/MyNavBt";

import { CommonTools } from "tools/commontools";
import SearchIcon from '@mui/icons-material/Search';

import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { SupportArticleContext } from "context/supportarticle.context";


type AdvertisementSearchItemProps = {
    obj: AdvertisementDto;
}
const AdvertisementSearchItem: React.FC<AdvertisementSearchItemProps> = ({
    obj,
    ...props
}) => {

    const { LL, setSearchFilterBlog, searchFilterBlog } = useResource();
    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");
    const {
        actions:{
            setSearchArticle,handleSearch
        }
      } = useContext(SupportArticleContext);
    const prepareGallery = () => {
        if (!obj) return;
        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 2000, 694);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])

    const [search, setSearch] = useState('');
    const handleSubmit = (event: any) => {
        event.preventDefault();
        handleSearch();
        setSearch('')
    };

    const handleChange = (event: any) => {
        setSearch(event.target.value);
        setSearchArticle(event.target.value)
    };

    const processItem = () => {
        if (!obj) return <></>;

        return (
            <Box className="bannerSearch" sx={{
                backgroundImage: `url(${imgUrl})`,
            }}
            >
                <Box className="container">
                    <Grid container>
                        <Grid item md={5} sm={12}>
                            <Typography className="name">
                                {ComponentCommonTools.processObjectField(obj, 'name')}
                            </Typography>
                            <Typography className="description" my={4}>
                                {ComponentCommonTools.processObjectField(obj, 'description')}
                            </Typography>
                            <Box >
                                <form onSubmit={handleSubmit}>
                                    <Box className="searchForm">
                                        <TextField
                                            label={LL("Search_for_solution")}
                                            onFocus={handleChange}
                                            onChange={handleChange}
                                            value={search}
                                            fullWidth
                                            sx={{

                                                "& label.Mui-focused":
                                                {
                                                    display: 'none'
                                                },
                                                "& .MuiOutlinedInput-root.Mui-focused": {

                                                    "& > fieldset": {
                                                        borderColor: "none",
                                                        borderWidth: '0px'
                                                    }
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": {
                                                        border: "none"
                                                    }
                                                }
                                            }}
                                            variant="outlined"
                                            className="input"


                                            {...props}
                                        />
                                        <Button type="submit">{ComponentCommonTools.processObjectField(obj, 'clabt')} <SearchIcon /></Button>
                                    </Box>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }

    return processItem();
};

export { AdvertisementSearchItem };
