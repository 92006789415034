import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class TicketDto implements Idto {
    
    id?: string;
    status?: number;
    type?: number;
    idoperator?: string;
    idstudent?: string;
    idteacher?: string;
    idticketcategory?: string;
    
  }

  export class PostTicketDto implements Idto {

    status?: number;
    type?: number;
    idoperator?: string;
    idstudent?: string;
    idteacher?: string;
    idticketcategory?: string;
  }


  export class RegisterTicketDto implements Idto {

    type?: number;
    idstudent?: string;
    idteacher?: string; 
    idticketcategory?: string;
    message?: string;

    
  }