import Idto from "interfaces/idto.interface";
import { Status } from "tools/status";
import { Types } from "tools/types";

class SectionSupportArticleCategoryDto {
  name: string;
  objects: Array<SupportArticleCategoryDto>;
  constructor(name: string, objects: Array<SupportArticleCategoryDto>) {
    this.name = name;
    this.objects = objects;
  }
}

export class SupportArticleCategoryDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  code?: string;
  level?: number;
  idparent?: string;
  type?: number;
  idlanguage?: any;
  language?: any;
  name?: any;
  haschildren?: boolean;
  idsupportarticlecategory?: string;
  gallery?: any;
  video?: any;
  maincode?: string;
  numberofarticles?: number;

  static groupByType(
    arr: Array<SupportArticleCategoryDto>
  ): Array<SectionSupportArticleCategoryDto> {
    const rez = new Array<SectionSupportArticleCategoryDto>();
    const typeStudentObjects = arr.filter(
      (x) => x.type === Types.TYPE_SUPPORT_ARTICLE_CATEGORY_STUDENT 
    );
    const typeTeacherObjects = arr.filter(
      (x) => x.type === Types.TYPE_SUPPORT_ARTICLE_CATEGORY_TEACHER 
    );

    rez.push(
      new SectionSupportArticleCategoryDto(
        Types.LL(Types.TYPE_SUPPORT_ARTICLE_CATEGORY_STUDENT),
        typeStudentObjects
      ),
      new SectionSupportArticleCategoryDto(
        Types.LL(Types.TYPE_SUPPORT_ARTICLE_CATEGORY_TEACHER),
        typeTeacherObjects
      )
    );

    return rez;
  }
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}

export class PostSupportArticleCategoryDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  code?: string;
  level?: number;
  idparent?: string;
  type?: number;
  allvalues?: any;
  idlanguage?: any;
  language?: any;
  name?: any;
  haschildren?: boolean;
  idsupportarticlecategory?: string;
  maincode?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}

export interface ISupportArticleTypeCategory {
  name: string;
  value: number;
}

export const SupportArticleTypeCategoryObjects: Array<ISupportArticleTypeCategory> =
  [
    {
      name: "Student",
      value: Types.TYPE_SUPPORT_ARTICLE_CATEGORY_STUDENT,
    },

    {
      name: "Teacher",
      value: Types.TYPE_SUPPORT_ARTICLE_CATEGORY_TEACHER,
    },
  ];
