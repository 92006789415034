import React, { useContext, useEffect } from "react";
import { ComponentCommonTools } from "tools/componentcommontools";
import { Box, Typography } from "@mui/material";
import { SupportArticleDto } from "dto/supportarticle/supportarticle.dto";
import { SupportArticleContext } from "context/supportarticle.context";
import { useResource } from "hook/useResource";

const SearchTopBlock: React.FC = () => {
  const {
    state: { searchArticle },
  } = useContext(SupportArticleContext);
  const { LL } = useResource();

  return (
    <Box>
      <Typography variant="h2">
        {LL("Result_of_search ")} "{searchArticle}"
      </Typography>
    </Box>
  );
};

export { SearchTopBlock };
