import { createContext, useState, useEffect } from "react";

import { useCookies } from "react-cookie";
// import { useCookiesContext } from "hook/useCookies";

import { UserDTO } from "dto/user/user.dto.ts";

import { logger } from "hoc/logger";
import UserService from "services/user/user.service";
import SignInDto from "dto/auth/signin.dto";
import SignInHashDto from "dto/auth/signinhash.dto";
import DateTools from "tools/date.tools";
import GeneralRepository from "repositories/general.repository";
import { RouteTools } from "tools/routetools";
import { Loading } from "components/general/Loading";
import UserSettingsService from "services/user/usersettings.service";
import SignUpDto from "dto/auth/signup.dto";
import { RegisterUserDto } from "dto/auth/registeruser.dto";
import { BecomeTeacherDto } from "dto/user/becometeacher.dto";
import { Types } from 'tools/types'
import { Status } from "tools/status";
import { useResource } from "hook/useResource";
import TeacherService from "services/teacher/teacher.service";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [cookies, setCookie, removeCookie] = useCookies();


  var inRefresh = false;

  const [token, setToken] = useState(null);
  const [jwt, setJWT] = useState(null);

  const [mustchangepassword, setMustChangePassword] = useState(false);

  const [nextLocation, setNextLocation] = useState(false);
  const [user, setUser] = useState(-1);
  const [roles, setRoles] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [usersettings, setUserSettings] = useState(null);
  const [student, setStudent] = useState(null);
  const [userService, setUserService] = useState(null);
  const [userSettingsService, setUserSettingsService] = useState(null);
  const [teacherService, setTeacherService] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [userInterface, setUserInterface] = useState(Types.STUDENT_INTERFACE);
  // ---------------------

  // ----------------------------------------
  useEffect(() => {
    checkIsLoading();
    if (user && user !== -1 && !checkCheckPasswordInit()) {
      handleMustChangePassword();
    }

  }, [user, roles, usersettings]);

  // const isTeacher = () => {
  //   const id = process.env.REACT_APP_TEACHER_ROLE_ID ?? '';
  //   if (id === '') return false;
  //   if (!roles) return false;
  //   if (!Array.isArray(roles)) return false;
  //   if (roles.length === 0) return false;
  //   if (roles.includes(id)) return true;

  //   return false;
  // };



  // const processSetTeacher = () =>{
  //   if (!teacherService) return;
  //   if (!user) return;
  //   if (!isTeacher()) return;
  //   teacherService.getByUserId(user.id, handleSetTeacher, {});
  // }

  // const handleSetTeacher = (result) =>{
  //   if (!result) return;
  //   if (!result.obj) return;
  //   setTeacher(result.obj);
  // }


  const handleMustChangePassword = () => {
    logger('handleMustChangePassword', mustchangepassword, user)
    if (mustchangepassword) {
      if (user.hasOwnProperty('statuspassword')) {
        if (user.statuspassword === Status.TEMPORARY) {
          RouteTools.setHistory("/changetemporarypassword", {});
        }
      }
    }
    // checkPasswordInit = true;
    setCookie("checkPasswordInit", true, { path: "/", expires: 0 });
  }

  const checkCheckPasswordInit = () => {
    const t = cookies &&
      cookies.checkPasswordInit != undefined &&
      cookies.checkPasswordInit != "undefined" &&
      cookies.checkPasswordInit != "null"
      ? cookies.checkPasswordInit
      : false;

    logger('checkCheckPasswordInit', t)

    return t;
  };

  const checkIsLoading = () => {
    if (!isLoading) return;

    logger(
      "authcheckIsLoadingauthcheckIsLoadingauthcheckIsLoadingauthcheckIsLoading",
      user,
      roles
    );

    if (user == -1) return;
    // if (roles == -1) return;

    setIsLoading(false);
  };

  // ----------------------------------------

  useEffect(() => {
    setUserService(new UserService());
    setUserSettingsService(new UserSettingsService());
    // setTeacherService(new TeacherService());
    checkToken();
  }, []);

  useEffect(() => {
    verifyToken();
    GeneralRepository.setToken(getToken);

    RouteTools.setUserInterface(getUserInterface);
    RouteTools.setUserInterfaceFunction(setUserInterface);

  }, [token, userInterface]);

  const getUserInterface = () => {
    return userInterface;
  }
  useEffect(() => {
    checkUser();
    // signintmp();
  }, [userService]);

  useEffect(() => {
    if (user === null) return;

    // if (user && window.location.pathname == "/login") {
    //   if (nextLocation) {
    //     var u = nextLocation.pathname + nextLocation.search;
    //   } else {
    //     var u = "/";
    //   }
    //   RouteTools.setHistory(u, {});
    //   setNextLocation(false);
    // }
    // else if (!user && window.location.pathname != "/login") {
    //   const t = {
    //     pathname: window.location.pathname,
    //     search: window.location.search,
    //   };
    //   setNextLocation(t);
    //   RouteTools.setHistory("/login", {});
    // }
  }, [user]);

  // ---------------------
  const checkToken = () => {
    var t =
      cookies.token != undefined &&
        cookies.token != "undefined" &&
        cookies.token != "null"
        ? cookies.token
        : "";

    processToken(t);
  };

  const processToken = (accesstoken) => {
    setToken(accesstoken);
    setCookie("token", accesstoken, { path: "/", expires: 0 });

    if (accesstoken.accesstoken != undefined) {
      setCookie("jwt", accesstoken.accesstoken, { path: "/", expires: 0 });
      setJWT(accesstoken.accesstoken);
    }
  };

  const getToken = () => {
    verifyToken();
    return jwt;
  };

  const verifyToken = () => {
    if (!token) return;

    const expiresin = token.expiresin;
    const untildate = token.untildate;
    const diff = expiresin * 0.1;

    const c = DateTools.getTimeStamp();
    const rd = untildate - c;

    if (rd > diff || rd < 0) {
      if (rd < 0) {
        signOut(true);
      }
      return;
    }

    refreshToken();
  };

  const refreshToken = () => {
    if (inRefresh) return;
    inRefresh = true;

    const _r =
      cookies._r != undefined &&
        cookies._r != "undefined" &&
        cookies._r != "null"
        ? cookies._r
        : 0;

    const r = _r ? true : false;

    const cbparameters = {};
    cbparameters.remember = r;
    cbparameters.jwt = jwt;

    userService.refreshToken(handleSetToken, cbparameters);
  };
  // ---------------------

  // ---------------------

  // ---------------------
  const signOut = (onlyUser?: boolean) => {
    onlyUser = onlyUser ?? false;
    setUser(false);
    setRoles(null);
    setUserSettings(null);
    setMustChangePassword(false);
    setTeacher(null);
    setStudent(null);
    setToken(null);
    setJWT(null);
    if (!onlyUser) removeCookie("_r");
    if (!onlyUser) removeCookie("_uid");
    if (!onlyUser) removeCookie("_uhash");
    if (!onlyUser) removeCookie("jwt");
    if (!onlyUser) removeCookie("token");
    if (!onlyUser) removeCookie("checkPasswordInit");
    if (!onlyUser) setUserInterface(Types.STUDENT_INTERFACE);
    if (!onlyUser) RouteTools.setHistory("/login", {});
  };

  const checkUser = () => {
    if (userService == undefined) return;
    if (userService == null) return;

    if (user != undefined && user != null && user != -1) return;

    setSavedUser();
  };

  // ---------------------
  const setSavedUser = () => {
    logger("Resultsignup_handleSetToken 111111111");
    const _uid =
      cookies._uid != undefined &&
        cookies._uid != "undefined" &&
        cookies._uid != "null"
        ? cookies._uid
        : "";

    const _uhash =
      cookies._uhash != undefined &&
        cookies._uhash != "undefined" &&
        cookies._uhash != "null"
        ? cookies._uhash
        : "";

    const _r =
      cookies._r != undefined &&
        cookies._r != "undefined" &&
        cookies._r != "null"
        ? cookies._r
        : 0;

    const r = _r ? true : false;

    if (!_uid || !_uhash) {
      setUser(false);
      return;
    }

    const signInHashDto = new SignInHashDto();
    signInHashDto.id = _uid;
    signInHashDto.hash = _uhash;

    const cbparameters = {};
    cbparameters.remember = r;

    userService.signInHash(signInHashDto, handleSetToken, cbparameters);
  };
  // ---------------------

  const processUser = (obj, cbparameters) => {
    if (
      cbparameters.remember != undefined &&
      cbparameters.remember != null &&
      cbparameters.remember
    ) {
      let exp = process.env.REACT_APP_USERCOOKIES_EXPIRE;
      exp = parseInt(exp);
      if (isNaN(exp)) exp = 0;

      setCookie("_r", "1", { path: "/", expires: DateTools.getDate(exp) });
      setCookie("_uid", obj.id, { path: "/", expires: DateTools.getDate(exp) });
      setCookie("_uhash", obj.hash, {
        path: "/",
        expires: DateTools.getDate(exp),
      });
    } else {
      setCookie("_r", "0", { path: "/" });
      setCookie("_uid", obj.id, { path: "/" });
      setCookie("_uhash", obj.hash, { path: "/" });
    }
  };

  const handleSetToken = (result?: any, cbparameters?: any, data?: any) => {
    logger('Resultsignup_handleSetToken', result);
    if (result.accesstoken != undefined && result.accesstoken != null) {
      processToken(result.accesstoken);
    }

    if (
      result.mustchangepassword != undefined &&
      result.mustchangepassword != null
    ) {
      setMustChangePassword(result.mustchangepassword);
    }
    if (result.obj != undefined && result.obj != null) {
      setUser(result.obj);
      processUser(result.obj, cbparameters);
    }
    if (result.roles != undefined && result.roles != null) {
      setRoles(result.roles);
    }
    if (result.usersettings != undefined && result.usersettings != null) {
      setUserSettings(result.usersettings);
    }
    if (result.hasOwnProperty('teacher') && result.teacher != undefined && result.teacher != null) {
      setTeacher(result.teacher);
    }
    if (result.hasOwnProperty('student') && result.student != undefined && result.student != null) {
      setStudent(result.student);
    }
    if (!result.err) {

      // RouteTools.setHistory("/", {});
      // handleMustChangePassword();
    }
    if (cbparameters && cbparameters.goToTeacher) {
      if (teacher || result.teacher) {
        RouteTools.setHistory("/teacherinterface", {});
      }
      else {
        RouteTools.setHistory("/", {});
      }
    }
    if (cbparameters && cbparameters.defaultRoute) {
      if (nextLocation) {
        const url = nextLocation;
        setNextLocation(false);
        RouteTools.setHistory(url, {})
      } else {
        RouteTools.setHistory("/", {});
      }
    }

    inRefresh = false;
  };

  const updateUserSettings = (field, value) => {
    if (usersettings == undefined) return;
    if (usersettings == null) return;

    const t = usersettings;

    t[field] = value;
    userSettingsService.update(t.id, null, {}, t);

    setUserSettings(t);
  };

  // ---------------------

  // ---------------------

  const signIn = (signInDto: SignInDto, r?: Boolean) => {
    if (userService == undefined) return false;
    if (userService == null) return false;

    r = r != undefined ? r : false;

    const cbp = {};
    cbp.remember = r;
    cbp.defaultRoute = true;
    
    setUserInterface(Types.STUDENT_INTERFACE);
    userService.signIn(signInDto, handleSetToken, cbp);
  };

  const signUp = (signUpDto: RegisterUserDto) => {
    if (userService == undefined) return false;
    if (userService == null) return false;
    if (userInterface !== Types.TEACHER_INTERFACE) setUserInterface(Types.STUDENT_INTERFACE);
    userService.register(signUpDto, handleSetToken, { defaultRoute: true });
  };

  const registerTeacher = (obj: BecomeTeacherDto) => {
    if (userService == undefined) return false;
    if (userService == null) return false;
    setIsLoading(true);
    userService.registerTeacher(obj, handleSetToken, { goToTeacher: true });

  };

  const signintmp = () => {
    if (userService != undefined && userService != null) {
      const signInDto = new SignInDto();
      // signInDto.identifier = "xxx";
      // signInDto.password = "ppp";
      signInDto.identifier = "postoser@gmail.com";
      signInDto.password = "q";

      const cbparameters = {};
      cbparameters.remember = true;

      userService.signIn(signInDto, handleSetToken, cbparameters);
    }
  };

  // ---------------------

  // const value = { user, token, signin, signout, checkToken, getToken };
  const value = {
    user,
    roles,
    usersettings,
    signIn,
    signOut,
    verifyToken,
    updateUserSettings,
    signUp,
    registerTeacher,
    setUserInterface,
    userInterface,
    teacher,
    student,
    setNextLocation,
    setStudent
  };

  return isLoading ? (
    <Loading />
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};
