import Idto from "interfaces/idto.interface";

export class SupportArticleRatingDto implements Idto {
  id?: string;
  idsupportarticle?: string;
  iduser?: string;
  rating?: number;
  date?: number;
  supportarticle?: any;
  user?: any;
}

export class PostSupportArticleRatingDto implements Idto {
  idsupportarticle: string;
  iduser: string;

  constructor(idsupportarticle: string, iduser: string) {
    this.idsupportarticle = idsupportarticle;
    this.iduser = iduser;
  }
}

export class RatingSupportArticleDto implements Idto {
    rating?: number;
  }
