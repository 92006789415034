import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
import { PostNomenclatureDto } from "dto/nomenclature/postnomenclature.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import GeneralService from "services/general.service";

export default class GeneralNomenclatureService extends GeneralService implements IService {
    repository;

    constructor(repository: any) {
        super();
        this.repository = repository;
        this.handleGetList = this.handleGetList.bind(this);
        this.handleGet = this.handleGet.bind(this);
    }

    fromTypePopulateToPostType(obj: PopulateTypeDto): PostNomenclatureDto {
        const object = new PostNomenclatureDto();

        object.idlanguage = obj.idlanguage;
        object.status = obj.status;
        object.ordercriteria = obj.ordercriteria;
        object.name = obj.name;
        object.idtype = obj.id;
        

        return object;
    }


    handleUpdate(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;

        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }

    async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        this.repository.update(id, this.handleUpdate, data, cbparameters);
    }

    handleDelete(result?: any, cbparameters?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters);
        }
    }

    async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.repository.delete(id, this.handleDelete, cbparameters);
    }



    handleGet(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;
        this.populateObject(rez.obj, result.obj, [
            { key: 'status', type: 'status' },
        ]);
        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

    return rez;
    }

    async get(id: string, cb?: any, cbparameters?: any,idLanguage?:string): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
       const t = await this.repository.get(id, this.handleGet, cbparameters,idLanguage);
        if (cb == undefined)
        {
            return this.handleGet(t);
        }
    }

    handleGetList(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultListDTO();

        rez = result;
        if (result.err) return;

        if (result.objects && rez.objects) {
            for (let index = 0; index < result.objects.length; index++) {
                const obj = result.objects[index];
                if (rez.objects[index]) {
                    this.populateObject(rez.objects[index], obj, [
                        { key: 'status', type: 'status' },
                    ]);

                }
            }
        }

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

        return rez;
    }

    async getList(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.repository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined)
        {
            return this.handleGetList(t);
        }
    }

    handleAdd(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }

    async add(
        cb?: any,
        cbparameters?: any,
        data?: any
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        this.repository.add(this.handleGetList, cbparameters, data);
    }


    getDefaultRequestListSelect(onPage?: number): RequestListDTO {
        onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
        if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
        var defaultRequestList = new RequestListDTO();
        defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
        defaultRequestList.onpage = onPage;
        var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
        defaultRequestSortCriteria.asc = true;
        defaultRequestSortCriteria.field = 'name';
        defaultRequestList.sortcriteria = []
        defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
        return defaultRequestList;
    }

    getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
        if (
          !obj.sortcriteria ||
          !Array.isArray(obj.sortcriteria) ||
          !obj.sortcriteria.length
        ) {
          var sobj = new RequestSortCriteriaDTO();
          sobj.asc = true;
          sobj.field = "name";
    
          obj.sortcriteria = [];
          obj.sortcriteria?.push(sobj);
        }
    
        return obj;
      }
}
