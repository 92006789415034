

import React, { useEffect, useState, useRef } from "react";

import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";


import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Stack,
    Typography,
    ListItemIcon,
    Box
} from "@mui/material";


import { VideoDto } from "dto/video/video.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ComponentCommonTools } from "tools/componentcommontools";
import VideoService from "services/video/video.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Status } from "tools/status";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { Types } from "tools/types";
import { VideoListItem } from "./VideoListItem";
import { CommonTools } from "tools/commontools";
import { Carousel } from "components/elements/Carousel/Carousel";
const videoService = new VideoService();
type VideoListProps = {
    parent: string
    typeDisplay?: number
}
const VideoListCarousel: React.FC<VideoListProps> = ({
    parent,
    typeDisplay = Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_CAROUSEL,
    ...props
}) => {
    const { LL, CC } = useResource();
    const [loading, setLoading] = useState<boolean>(false);
    const [objects, setObjects] = useState<Array<VideoDto>>([]);
    const { saveCache, getCache } = useResource();

    const getList = async () => {

        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC('tOnPageVideoListCarousel', '10'));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];



        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);


        f = new RequestFilterDTO();
        f.field = "parent";
        f.values = [parent];
        reqList.filters.push(f);
        setLoading(true);

        const cd = getCache(reqList);

        if (cd !== false) {
          setObjects(cd);
          setLoading(false);
          return;
        }

        
        const data = await videoService.getList(undefined, {}, reqList);

        const rez = !data || !data.objects ? [] : data.objects;
    
        setObjects(rez);
        setLoading(false);

        saveCache(rez, reqList);
    };

  
    useEffect(() => {
        getList();
    }, [parent]);


    const processItem = (item: VideoDto, i: number) => {
        if (!item) return (<></>);

        return (       
        
            <VideoListItem index={i} obj={item} typeDisplay={typeDisplay} />
        
        );
    }
    const processLessonList = () => {
        if (loading) return <Skeleton variant="rectangular" />;
        if (!objects.length) return <></>;
        const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(parseInt(CC("DefaultOnPageVideoListCarousel", "4")), objects.length)
        return (

                <Carousel _slidesToShow={slidesToShow}>
                    {objects.map((item: VideoDto, i: number) => {
                        return processItem(item, i);
                    })}
                </Carousel>

        );
    };
    return (processLessonList())
};

export { VideoListCarousel };