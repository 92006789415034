import React from "react";

import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { logger } from "hoc/logger";

import { useReactPath } from "hook/useReactPath";

import { RouteTools } from "tools/routetools";

import MD5 from "crypto-js/md5";

import { useAuth } from "hook/useAuth";

import { v4 as uuidv4 } from "uuid";
import { useResource } from "hook/useResource";
import { Login } from "components/auth/Login";
import { NotFound } from "./NotFound";
import { Forbidden } from "./Forbidden";
import { HomePage } from "./HomePage";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { TeacherSignUp } from "../auth/TeacherSignUp";
import { SignUp } from "components/auth/SignUp";
import { PersonalCabinet } from "components/user/PersonalCabinet";
import FAQPage from "components/faq/FAQPage";
import { ForgotPassword } from "components/auth/ForgotPassword";
import { EditUserProfile } from "components/user/EditUserProfile";
import { ChangeTemporaryPassword } from "components/auth/ChangeTemporaryPassword";

import { PageStatic } from "components/static/page/PageStatic";

import { BlogBlock } from "components/static/blog/BlogBlock";
import { BlogStatic } from "components/static/blog/BlogStatic";

import { Loading } from "./Loading";

import { Types } from "tools/types";

import { CommonTools } from "tools/commontools";
import { TeacherDrawer } from "components/elements/Drawer/TeacherDrawer";

import { SignUpGoogleResult } from "components/auth/openids/SignUpGoogleResult";

import { Checkout } from "components/general/Checkout";
import { FAQList } from "components/faq/FAQList";
import { SupportArticleBlock } from "components/supportarticle/SupportArticleBlock";
import { SupportArticleCategoryBlock } from "components/supportarticlecategoryblock/SupportArticleCategoryBlock";
import { SupportArticleSearchBlock } from "components/supportarticlesearch/SupportArticleSearchBlock";

const MainLayout = ({ ...props }) => {
  const { currentRoute, setCurrentRoute } = useResource();
  const location = useLocation();
  const pathObject = useReactPath();

  const { user, userInterface } = useAuth();
  const [_currentpath, setCurrentPath] = React.useState("");
  const [_mainobject, setMainObject] = React.useState("");

  const processPathObject = async () => {
    var h = MD5(pathObject.path).toString();
    var hr = MD5(pathObject.path).toString();

    var routeobj = await RouteTools.parseLocation(location);

    routeobj.historystate = pathObject.historystate;

    if (routeobj.url == _currentpath) return;
    setCurrentPath(routeobj.url);

    setCurrentRoute(routeobj);
  };

  useEffect(() => {
    processPathObject();
  }, [pathObject]);

  useEffect(() => {
    if (currentRoute && currentRoute._mainobject) {
      setMainObject(currentRoute._mainobject);
    }
  }, [currentRoute]);

  // ----------------------------------

  const processStandard = () => {
    if (!_mainobject) return false;

    if (_mainobject == "homepage")
      return <HomePage currentRoute={currentRoute} />;

    if (_mainobject == "login") return <Login currentRoute={currentRoute} />;

    if (_mainobject == "signup") return <SignUp currentRoute={currentRoute} />;

    if (_mainobject == "signup-google-result")
      return <SignUpGoogleResult currentRoute={currentRoute} />;

    if (_mainobject == "changetemporarypassword")
      return <ChangeTemporaryPassword currentRoute={currentRoute} />;

    if (_mainobject == "faq") return <FAQList currentRoute={currentRoute} />;

    if (_mainobject == "forgotpassword")
      return <ForgotPassword currentRoute={currentRoute} />;

    if (_mainobject == "forbidden")
      return <Forbidden currentRoute={currentRoute} />;

    if (_mainobject == "notfound")
      return <NotFound currentRoute={currentRoute} />;

    // if (_mainobject == "personalcabinet") return <PersonalCabinet currentRoute={currentRoute} />;
    return false;
  };

  const processUser = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;
    // if (RouteTools.modules.indexOf(_mainobject) == -1) return false;
    // if (_mainobject == "profile") {
    //   if (path.length === 1) return <PersonalCabinet currentRoute={currentRoute} />
    //   if (path[1] === "edit") return <EditUserProfile currentRoute={currentRoute} />
    //   if (path[1] === "course") return <StudentCoursePage currentRoute={currentRoute} />
    //   if (path[1] === "courseenrollment") return <StudentCourseDetail currentRoute={currentRoute} />
    //   if (path[1] === "faq") return <StudentInterfaceFAQBlock currentRoute={currentRoute} />;
    //   if (path[1] === "testimonial") return <StudentInterfaceTestimonialBlock currentRoute={currentRoute} />;
    //   if (path[1] === "notification") return <StudentNotificationBlock currentRoute={currentRoute} />;

    // };

    return false;
  };

  const processCourse = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;
    // if (RouteTools.modules.indexOf(_mainobject) == -1) return false;
    // if (_mainobject == "coursecategory") {
    //   return <CourseCategoryPage currentRoute={currentRoute} />
    // };
    // if (_mainobject == "course") {
    //   return <CoursePage currentRoute={currentRoute} />
    // }

    return false;
  };

  const processPage = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    if (_mainobject == "page") {
      return <PageStatic currentRoute={currentRoute} />;
    }

    return false;
  };

  const processBlog = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    if (_mainobject == "blog") {
      return <BlogBlock currentRoute={currentRoute} />;
    }

    if (_mainobject == "blogpage") {
      return <BlogStatic currentRoute={currentRoute} />;
    }
    return false;
  };

  const processSupportArticle = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    // if (_mainobject == "blog") {
    //   return <BlogBlock currentRoute={currentRoute} />
    // };

    if (_mainobject == "supportarticlepage") {
      return <SupportArticleBlock currentRoute={currentRoute} />;
    }

    if (_mainobject == "supportarticlesearch") {
      return <SupportArticleSearchBlock currentRoute={currentRoute} />;
    }
    return false;
  };

  const processSupportArticleCategory = () => {
    if (!_mainobject) return false;

    if (_mainobject == "supportarticlecategory") {
      return <SupportArticleCategoryBlock currentRoute={currentRoute} />;
    }
    return false;
  };

  const processTeacher = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;
    // if (RouteTools.modules.indexOf(_mainobject) == -1) return false;
    // if (_mainobject == "teacherpage") {
    //   return <PublicTeacherProfile currentRoute={currentRoute} />

    // };

    // if (_mainobject == "teacher") {
    //   return <TeacherList currentRoute={currentRoute} />
    // }

    return false;
  };

  const processTeacherInterface = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    // if (_mainobject !== "teacherinterface") return false;

    // if (!user) return false;
    // if (userInterface !== Types.TEACHER_INTERFACE) return false;
    // if (path.length === 1) return <TeacherDashboard currentRoute={currentRoute} />;
    // if (path.length === 2) {
    //   if (path[1] === "course") return <TeacherCoursePage currentRoute={currentRoute} />;
    //   if (path[1] === "addcourse") return <CreateCourseFirstStep currentRoute={currentRoute} />;
    //   if (path[1] === "testresult") return <TestResultBlock currentRoute={currentRoute} />;
    //   if (path[1] === "profile") return <TeacherProfile currentRoute={currentRoute} />;
    //   if (path[1] === "editprofile") return <EditTeacherProfile currentRoute={currentRoute} />;
    //   if (path[1] === "faq") return <TeacherInterfaceFAQBlock currentRoute={currentRoute} />;
    //   if (path[1] === "testimonial") return <TeacherInterfaceTestimonialBlock currentRoute={currentRoute} />;
    //   if (path[1] === "student") return <TeacherInterfaceStudentBlock currentRoute={currentRoute} />;
    //   if (path[1] === "notification") return <TeacherNotificationBlock currentRoute={currentRoute} />;
    //   if (path[1] === "teacherrequest") return <TeacherInterfaceRequestBlock currentRoute={currentRoute} />;
    //   if (path[1] === "courseteacherrequest") return <CourseTeacherInterfaceRequestBlock currentRoute={currentRoute} />;
    //   if (path[1] === "message") return <TeacherMessageBlock currentRoute={currentRoute} />;
    // }
    // if (path.length === 3) {
    //   if (path[1] === "editcourse") return <TeacherEditCourse currentRoute={currentRoute} />;
    //   if (path[1] === "classroom") return <ClassroomBlock currentRoute={currentRoute} />;
    // }
    // if (path.length >= 3) {

    //   if (path[1] === "classroom") return <ClassroomBlock currentRoute={currentRoute} />;
    // }

    return false;
  };

  const processStudent = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    // if (_mainobject == "student") {
    //   return <PublicStudentProfile currentRoute={currentRoute} />
    // };

    return false;
  };

  // ----------------------------------

  const processMainObject = () => {
    let t: any = false;

    t = processStandard();
    if (t) return t;

    // t = processUser();
    // if (t) return t;

    // t = processCourse();
    // if (t) return t;

    // t = processTeacher();
    // if (t) return t;

    t = processPage();
    if (t) return t;

    t = processBlog();
    if (t) return t;

    t = processSupportArticle();
    if (t) return t;

    t = processSupportArticleCategory();
    if (t) return t;

    // t = processTeacherInterface();
    // if (t) return t;

    return <Loading />;
  };

  const processMainLayout = () => {
    return (
      <>
        <header>
          <div className="container">
            <Header currentRoute={currentRoute} />
          </div>
        </header>
        <main>{processMainObject()}</main>
        <footer>
          <Footer currentRoute={currentRoute} />
        </footer>
      </>
    );
  };

  return processMainLayout();
};

export { MainLayout };
