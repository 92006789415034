import { VideoDto } from "dto/video/video.dto";

export class VideoTools {


    public static getVideoImageUrl(obj: VideoDto) {
        if (!obj) return '';
        if (!obj.videoserver) return '';
        if (!obj.videoid) return '';
        if (obj.videoserver === 'youtube')
            return 'https://img.youtube.com/vi/' + obj.videoid + '/maxresdefault.jpg';
        return '';
    }
}

