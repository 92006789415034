import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import { MyNavBt } from "components/general/MyNavBt";
import { useAuth } from "hook/useAuth";

const SignUpBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const mainUrl = process.env.REACT_APP_WWW_SERVER ? process.env.REACT_APP_WWW_SERVER : '';
  const urlToSignUp = mainUrl ? mainUrl + "/signup" : "";
  const { user } = useAuth();

  return user == undefined ? (
    <></>
  ) : (
    <MyNavBt
      href={urlToSignUp}
      className="btnTop btnSignUpTop"
      target="_blank" 
      rel="noopener noreferrer"
    >
      {LL("BT_SignUpTop")}
    </MyNavBt>
  );
};

export { SignUpBlock };
