import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { ContactFormDialog } from "./ContactFormDialog";
import { Box } from "@mui/material";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { useAuth } from "hook/useAuth";

const ContactBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const { user, setNextLocation } = useAuth();

  const [openForm, setOpenForm] = useState<boolean>(false);

  const processDialog = () => {
    if (!openForm) return <></>;
    return <ContactFormDialog open={openForm} setOpen={setOpenForm} />;
  };

  const handleOpen = () => {
    if (!user) {
      logger("ContactBlock", currentRoute);
      const url = currentRoute
        ? currentRoute.url
          ? currentRoute.url
          : "/"
        : "/";
      setNextLocation(url);
      RouteTools.setHistory("/login", {});
    } else {
      setOpenForm(true);
    }
  };

  return (
    <Box>
      <MyButton onClick={handleOpen} variant="contained" color="primary">{LL("Contact_Us")}</MyButton>
      {processDialog()}
    </Box>
  );
};

export { ContactBlock };
