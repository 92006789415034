import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { CourseFilterDto } from "dto/course/coursefilter.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import CourseFilterService from "services/course/coursefilter.service";
import ResultListDTO from "dto/app/resultlist.dto";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
  Divider
} from "@mui/material";
import { logger } from "hoc/logger";

import { ComponentCommonTools } from "tools/componentcommontools";
import { PageDto } from "dto/page/page.dto";
import PageService from "services/page/page.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { HtmlTag } from "components/general/HtmlTag";
import { MainObjectImage } from "components/elements/media/MainObjectImage";
import { ObjectGallery } from "components/elements/media/ObjectGallery";
import { ObjectAttachment } from "components/elements/media/ObjectAttachemnt";
import { MyBreadcrumbsCourse } from "components/elements/MyBreadcrumbs/MyBreadcrumbsCourse";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";

type PageStaticProps = {
  currentRoute: any;
};

const pageService = new PageService();

const PageStatic: React.FC<PageStaticProps> = ({ currentRoute, ...props }) => {
  const { LL } = useResource();
  const [isLoading, setIsLoading] = useState(true);

  const [idObject, setIdObject] = useState<string>("");
  const [obj, setObj] = useState<PageDto>();

  const getIdObj = () => {
    let id =
      currentRoute &&
        currentRoute.processedData &&
        currentRoute.processedData.idobj
        ? currentRoute.processedData.idobj
        : "";

    if (!id) {
      id =
        currentRoute &&
          currentRoute.processedData &&
          currentRoute.processedData._obj &&
          currentRoute.processedData._obj.id
          ? currentRoute.processedData._obj.id
          : id;
    }

    if (!id) {
      id =
        currentRoute && currentRoute._getVars && currentRoute._getVars.id
          ? currentRoute._getVars.id
          : id;
    }

    let _obj =
      currentRoute &&
        currentRoute.processedData &&
        currentRoute.processedData._obj
        ? currentRoute.processedData._obj
        : undefined;

    logger("getObj", id, _obj);
    setIdObject(id);
    setObj(_obj);
  };

  useEffect(() => {
    getIdObj();
  }, [currentRoute]);

  const getObj = () => {
    if (idObject == undefined || idObject == "" || idObject == null) return;

    if (obj != undefined && obj.id != undefined && obj.id == idObject) {
      return;
    }
    logger("getObj", idObject);
    pageService.get(idObject, loadObjects);
  };

  useEffect(() => {
    getObj();
  }, [idObject]);

  const loadObjects = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      const _obj: PageDto | undefined =
        result.obj != undefined ? (result.obj as PageDto) : undefined;
      setObj(result.obj);
    }
  };

  const processBreadcrumbChildren = (): BreadcrumbChildrenDto[] => {
    const breadcrumbArray: BreadcrumbChildrenDto[] = [];
    const breadcrumb = new BreadcrumbChildrenDto();
    breadcrumb.url = '/';
    breadcrumb.label = LL('crumb_homepage');

    breadcrumbArray.push(breadcrumb);
    if (obj && obj.hasOwnProperty('name') && obj.name) {
      const breadcrumbChildrenItem = new BreadcrumbChildrenDto();
      breadcrumbChildrenItem.url = '';
      breadcrumbChildrenItem.label = obj.name;
      breadcrumbArray.push(breadcrumbChildrenItem);
    }

    return breadcrumbArray;
  }

  const processBreadcrumb = () => {
    if (!obj) return <></>;
    const breadcrumbChildren = processBreadcrumbChildren();
    return <Box className="breadcrumbs"><MyBreadcrumbsCourse currentRoute={currentRoute} childs={breadcrumbChildren} /></Box>
  }

  const checkLoading = () => {
    let load = false;

    if (!obj || obj == undefined || obj == null) load = true;

    setIsLoading(load);
  };

  useEffect(() => {
    checkLoading();
  }, [idObject, obj]);

  const processMainImage = () => {
    if (!obj) return <></>;

    const _gallery = obj.gallery ? obj.gallery : undefined;
    return <Box className="image"><MainObjectImage gallery={_gallery} /></Box>;
  };

  const processHead = () => {
    if (!obj) return <></>;
    return <Typography variant="h2">{obj.name}</Typography>;
  };

  const processContent = () => {
    if (!obj) return <></>;
    return <HtmlTag content={obj.content} />;
  };

  const processGallery = () => {
    if (!obj) return <></>;

    const _gallery = obj.gallery ? obj.gallery : undefined;
    return <ObjectGallery serverGallery={_gallery} nonMain={true} />;
  };

  const processAttachments = () => {
    // if (!objects.length) return <></>;
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("attachment")) return <></>;
    if (!obj.attachment) return <></>;

    return (
      <ObjectAttachment serverAttachment={obj.attachment} nonMain={true} />
    );
  };

  const processVideos = () => {
    // if (!objects.length) return <></>;
    return <>TEMPORAR</>;
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <Box className='pageGeneral'>
      <Box className="onlyBread" py={3}><Box className="container">{processBreadcrumb()}</Box></Box>

      <Box className="container">{processMainImage()}</Box>
      <Box className="container" py={4}>{processHead()}</Box>
      <Divider />
      <Box className="container">{processContent()}</Box>
      {/* <Box>{processGallery()}</Box>
      <Box>{processAttachments()}</Box>
      <Box>{processVideos()}</Box> */}
    </Box>
  );
};

export { PageStatic };
