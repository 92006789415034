import React, { useState } from 'react';
import { TopLogoBlock } from './TopLogoBlock';
import PageComponentProps from 'interfaces/pagecomponentprops.interface';
import { TopMenuBlock } from 'components/menu/TopMenuBlock';
import { SignUpTeacherBlock } from 'components/user/SignUpTeacherBlock';
import { SignUpBlock } from 'components/user/SignUpBlock';
import { SignInBlock } from "components/user/SignInBlock";
import { FavoriteBlock } from "components/user/FavoriteBlock";
import { SearchBlock } from "components/general/SearchBlock";
import { TopBar } from 'components/general/TopBar';
import { FAQBlock } from 'components/faq/FAQBlock';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useResource } from "hook/useResource";
import { useAuth } from 'hook/useAuth';
import { UserInterfaceBlock } from 'components/user/UserInterfaceBlock';


import { MainDrawer } from 'components/elements/Drawer/MainDrawer';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { Types } from 'tools/types';
import { TeacherDrawer } from 'components/elements/Drawer/TeacherDrawer';
import { CommonTools } from 'tools/commontools';
import { BottomBackMainSite } from './BottomBackMainSite';
// import { NotificationTopMenu } from 'components/notification/NotificationTopMenu';

const Header: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { user, userInterface } = useAuth();
  const { isTeacher } = useResource();

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  }

  const processUserActionBlock = () => {
    if (!user) {
      return (
        <>
          <SignUpBlock currentRoute={currentRoute} />
          <SignInBlock currentRoute={currentRoute} />
        </>
      )
    }
    else {
      return (
        <>
          <TopBar currentRoute={currentRoute} />
        </>
      )
    }
  }

  return (

    <Grid container className='row headerStyle'>
      <Grid item className="el1" xs={1}>
        <IconButton onClick={handleOpenDrawer}>
          <MenuIcon />
        </IconButton>
        <MainDrawer
          open={openDrawer}
          setOpen={setOpenDrawer}
          currentRoute={currentRoute} />
      </Grid>
      <Grid item lg={3} xs={9} className="el2">
        <BottomBackMainSite currentRoute={currentRoute} />
      </Grid>
      <Grid item lg={6} xs={11} className="el3">
        <TopLogoBlock currentRoute={currentRoute} />
      </Grid>
      <Grid  item lg={3} className="el4"  display='flex' justifyContent='flex-end'>
        {processUserActionBlock()}
      </Grid>
    </Grid>
  );
};

export { Header };
