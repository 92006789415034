import React, { useState, useEffect, useContext } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box, Typography, Grid } from "@mui/material";
import { SupportArticleCategoryLevel } from "./SupportArticleCategoryLevel";
import { CommonTools } from "tools/commontools";
import { NoResult } from "components/general/NoResult";
import { SupportArticleList } from "./SupportArticleList";
import { SupportArticleCategoryMenuList } from "components/supportarticlecategory/SupportArticleCategoryMenuList";
import { SupportArticleContext } from "context/supportarticle.context";
import { SupportArticleCategoryDto } from "dto/supportarticle/supportarticlecategory.dto";
import { ComponentCommonTools } from "tools/componentcommontools";
import { Divider } from "@material-ui/core";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";
import { useResource } from "hook/useResource";
import { MyBreadcrumbsCourse } from "components/elements/MyBreadcrumbs/MyBreadcrumbsCourse";

const SupportArticleCategoryBlock: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const {
    state: { selectedMainCategory, articleSupportCategoryHomeObjects },
    actions: { setSelectedMainCategory },
  } = useContext(SupportArticleContext);
  const { LL } = useResource();

  useEffect(() => {
    setSelectedMainCategory(
      CommonTools.getIdFromPath(currentRoute, "supportarticlecategory", 0, 1)
    );
  }, []);

  const [obj, setObj] = useState<SupportArticleCategoryDto | null>(null);

  const processObject = () => {
    if (!articleSupportCategoryHomeObjects) return;
    if (!selectedMainCategory) return;
    const obj = articleSupportCategoryHomeObjects.find(
      (item) => item.id === selectedMainCategory
    );
    if (!obj) return;
    setObj(obj);
  };

  useEffect(() => {
    processObject();
  }, [selectedMainCategory, articleSupportCategoryHomeObjects]);

  const processTop = () => {
    if (!obj) return <></>;
    return (
      <>
        <Typography variant="h2">
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Typography>
      </>
    );
  };

  const processBreadcrumbChildren = (): BreadcrumbChildrenDto[] => {
    const breadcrumbArray: BreadcrumbChildrenDto[] = [];
    let breadcrumb = new BreadcrumbChildrenDto();
    breadcrumb.url = "/";
    breadcrumb.label = LL("crumb_homepage");
    breadcrumbArray.push(breadcrumb);

    if (obj && obj.hasOwnProperty("name") && obj.name) {
      const breadcrumbChildrenItem = new BreadcrumbChildrenDto();
      breadcrumbChildrenItem.url = "";
      breadcrumbChildrenItem.label = obj.name;
      breadcrumbArray.push(breadcrumbChildrenItem);
    }

    return breadcrumbArray;
  };
  const processBreadcrumb = () => {
    if (!obj) return <></>;
    const breadcrumbChildren = processBreadcrumbChildren();
    return (

      <Box className="breadcrumbs">
        <MyBreadcrumbsCourse
          currentRoute={currentRoute}
          childs={breadcrumbChildren}
        />
      </Box>
    );
  };

  return selectedMainCategory ? (
    <Box className="pageGeneral">
    <Box className="onlyBread" py={3}>
        <Box className="container">{processBreadcrumb()}</Box>
      </Box>
    <Box className="supportarticleDetail">
      
      <Box className="container" sx={{ my: 4 }}>
        {processTop()}
      </Box>
      <Divider></Divider>
      <Box className="bg-left" mt={2}>
        <Box className="container">
          <Grid container spacing={2}>
            <Grid item sm={3}>
              <SupportArticleCategoryMenuList />
            </Grid>
            <Grid item sm={9} className="content-support">
              <Box sx={{ my: 3 }}>
                <SupportArticleList
                  currentRoute={currentRoute}
                  idCategory={selectedMainCategory}
                />
              </Box>
              <Box sx={{ my: 3 }}>
                <SupportArticleCategoryLevel
                  currentRoute={currentRoute}
                  level={1}
                  idparent={selectedMainCategory}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
    </Box>
  ) : (
    <NoResult identifier="articles" />
  );
};

export { SupportArticleCategoryBlock };
