import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { BlogDto } from 'dto/blog/blog.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { Box, Stack, Divider, ListItemButton, ListItemText, Link } from "@mui/material";

import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import { HtmlTag } from "components/general/HtmlTag";

type SimilarBlogItemProps = {
    obj: BlogDto;
}

const SimilarBlogItem: React.FC<SimilarBlogItemProps> = ({ obj, ...props }) => {

    const { LL } = useResource();

    const handleOnClick = (obj: BlogDto) => {
        if (!obj) return;
        if (!obj.hasOwnProperty('fullurl')) return;
        if (!obj.fullurl) return;
        const url = obj.fullurl;
        RouteTools.setHistory(url, {})
    }
    const processItem = () => {
        if (!obj) return <></>
        return (
        <ListItemButton
            onClick={() => handleOnClick(obj)}
        >
            <ListItemText>
                {ComponentCommonTools.processObjectField(obj, 'name')}
            </ListItemText>
        </ListItemButton>)
    }


    return processItem();
};

export { SimilarBlogItem };
