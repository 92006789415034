import React, { useContext, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { RouteTools } from "tools/routetools";

import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { SupportArticleContext } from "context/supportarticle.context";

type ArticleBreadcrumbSearchProps = {
  currentRoute: any;
};
const ArticleBreadcrumbSearch: React.FC<ArticleBreadcrumbSearchProps> = ({
  currentRoute,
}) => {
  const {
    actions: { setSearchArticle, setCodeFilterCriteria },
    state: { searchArticle },
  } = useContext(SupportArticleContext);
  const { LL } = useResource();
  const [search, setSearch] = useState(searchArticle);
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setCodeFilterCriteria("");
    setSearchArticle(search);
    handleNav();
  };

  const handleChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleNav = () => {
    if (!currentRoute) return;
    if (currentRoute._mainobject === "supportarticlesearch") return;
    RouteTools.setHistory("/supportarticlesearch", {});
  };

  const processItem = () => {
    return (
      <Box>
        <form onSubmit={handleSubmit}>
          <Box className="searchForm-bread">
            <TextField
              label={LL("Search_for_solution")}
              onFocus={handleChange}
              onChange={handleChange}
              value={search}
              sx={{border:'0px', }}
            />
            <Button type="submit">
              <SearchIcon />
            </Button>
          </Box>
        </form>
      </Box>
    );
  };

  return processItem();
};

export { ArticleBreadcrumbSearch };
