import React, { useContext, useEffect } from "react";
import { useResource } from "hook/useResource";

import Skeleton from "@mui/material/Skeleton";
import { Grid, List, ListItem, Typography } from "@mui/material";

import { SupportArticleContext } from "context/supportarticle.context";
import { SupportArticleCategoryDto } from "dto/supportarticle/supportarticlecategory.dto";
import { SupportArticleCategoryItem } from "./SupportArticleCategoryItem";

const SupportArticleCategoryList: React.FC = () => {
  const { LL } = useResource();
  const {
    state: {
      articleSupportCategoryHomeObjects,
      isLoadingHomePageArticleCategory,
      
    },
  } = useContext(SupportArticleContext);

  

  const processItem = (obj: SupportArticleCategoryDto, index: number) => {
    if (!obj) return <></>;

    return (
      <Grid item sm={6} md={4} key={index} className="category">
        <ListItem key={index} sx={{ p: 0, m: 0 }} className="item-category">
          
          <SupportArticleCategoryItem obj={obj} />
        </ListItem>
      </Grid>
    );
  };

  const processList = () => {
    if (!articleSupportCategoryHomeObjects.length) return <></>;

    return (
      <>
        <Typography variant="h2" my={2}>
          {LL("HomeBlogCategoryList_TITLE")}
        </Typography>

        <List>
          <Grid container spacing={2}>
            {articleSupportCategoryHomeObjects.map(
              (item: SupportArticleCategoryDto, i: number) => {
                return processItem(item, i);
              }
            )}
          </Grid>
        </List>
      </>
    );
  };

  return isLoadingHomePageArticleCategory ? <Skeleton /> : processList();
};

export { SupportArticleCategoryList };
