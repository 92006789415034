import Idto from "interfaces/idto.interface";
import { Status } from "tools/status";

export class SupportArticleDto implements Idto {
  id?: string;

  type?: number;

  status?: number;

  idsupportarticlecategory?: string;
  date?: number;
  allvalues?: any;
  idlanguage?: any;
  name?: any;
  url?: any;
  title?: any;
  keymeta?: any;
  descriptionmeta?: any;
  content?: any;
  supportarticlecategory?: any;
  attachment?: any;
  gallery?: any;
  video?: any;
  language?: any;
  fullurl?: any;
  idsupportarticle?: string;
  shortdescription?: string;

  constructor() {
    this.status = Status.ACTIVE;
  }
}

export class PostSupportArticleDto implements Idto {
  id?: string;
  type?: number;
  status?: number;
  idsupportarticlecategory?: string;
  idlanguage?: any;
  name?: any;
  url?: any;
  title?: any;
  keymeta?: any;
  descriptionmeta?: any;
  content?: any;
  idsupportarticle?: string;

  constructor() {
    this.status = Status.ACTIVE;
  }
}
