import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";


import { Box, Typography } from "@mui/material";



import { RouteTools } from "tools/routetools";
import { BlockBlogCategory } from "./BlockBlogCategory";

type BlogCategoryMenuProps = {
    currentRoute: any;
    idBlogCategory: string;
    setIdBlogCategory: any;
    idTypeBlogCategory: string;
    setIdTypeBlogCategory: any;
    
}
const BlogCategoryMenu: React.FC<BlogCategoryMenuProps> = ({
    currentRoute,
    idBlogCategory,
    setIdBlogCategory,
    idTypeBlogCategory,
    setIdTypeBlogCategory,
    
    ...props
}) => {


    const { LL, setSearchFilterBlog } = useResource();
    const studentIdTypeBlogCategory = process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY ?
    process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY : "";
const teacherIdTypeBlogCategory = process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY_TEACHER ?
    process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY_TEACHER : "";
    


    const handleSetAllCategories = () => {
        setIdBlogCategory("");
        setIdTypeBlogCategory("");
        setSearchFilterBlog("");
    }

    const handleSetStudentCategories = () => {
        setIdTypeBlogCategory(studentIdTypeBlogCategory);
        setIdBlogCategory("");
        setSearchFilterBlog("");
    }

    const handleSetTeacherCategories = () => {
        setIdTypeBlogCategory(teacherIdTypeBlogCategory);
        setIdBlogCategory("");
        setSearchFilterBlog("");
    }

    const processBlogCategoryList = () => {
        return (<Box >
            <Typography
                variant="h3"
                my={3}
                onClick={handleSetAllCategories}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#0072EF',
                    },
                }}
            >
                {LL("All_Categories")}
            </Typography>
            <Typography
                variant="h4"
                my={3}
                onClick={handleSetStudentCategories}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#0072EF',
                    },
                }}
            >
                {LL("Student_Categories")}
            </Typography>
            <BlockBlogCategory
                idTypeBlogCategory={studentIdTypeBlogCategory}
                currentRoute={currentRoute}
                setBlogCategoryId={setIdBlogCategory}
                setIdTypeBlogCategory={setIdTypeBlogCategory}
            />

            <Typography
                variant="h4"
                my={3}
                onClick={handleSetTeacherCategories}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#0072EF',
                    },
                }}
            >
                {LL("Teacher_Categories")}
            </Typography>
            <BlockBlogCategory
                idTypeBlogCategory={teacherIdTypeBlogCategory}
                currentRoute={currentRoute}
                setBlogCategoryId={setIdBlogCategory}
                setIdTypeBlogCategory={setIdTypeBlogCategory}
            />
        </Box>)
    }
    return processBlogCategoryList()

};

export { BlogCategoryMenu };