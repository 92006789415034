import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { AdvertisementSearch } from "components/advertisement/AdvertisementSearch";
const HomeSearchBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  
  return (<AdvertisementSearch currentRoute={currentRoute}/>  );
};

export { HomeSearchBlock };
