import React from "react";
import { ComponentCommonTools } from "tools/componentcommontools";
import { FormControlLabel, Radio } from "@mui/material";

import { SupportArticleCategoryDto } from "dto/supportarticle/supportarticlecategory.dto";
import { Typography } from "@material-ui/core";

type Props = {
  item: SupportArticleCategoryDto;
};
const CategorySearchCodeFilterItem: React.FC<Props> = ({ item }) => {
  const processCode = () => {
    if (!item) return "";
    if (!item.hasOwnProperty("maincode")) return "";
    if (!item.maincode) return "";
    return item.maincode;
  };

  const processNumOdArticles = () => {
    if (!item) return <></>;
    if (!item.hasOwnProperty("numberofarticles")) return <></>;
    if (!item.numberofarticles) return <></>;
    return <>({item.numberofarticles})</>;
  };
  return (
    <FormControlLabel
      value={processCode()}
      control={<Radio />}
      label={
        <>
          {ComponentCommonTools.processObjectField(item, "name")}{" "}
          {processNumOdArticles()}
        </>
      }
    />
  );
};

export { CategorySearchCodeFilterItem };
